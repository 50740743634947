import React, { useEffect, useState } from 'react'
//import Layout from "../layout/layout";
import { getBetHistory } from '../../utility/Apis'
//import { useMyContext } from "../context";
import AccountLayout from '.'
import { Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import PreArrow from '../common/prevArrow'
import NextArrow from '../common/nextArrow'
const pageLimit = 10
function BetHistory() {
  // const { userDetails } = useMyContext();
  let [betHistory, setBetHistory] = useState([])
  let [loading, setLoading] = useState(false)
  const [betHistoryCount, setBetHistoryCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [filterVal, setFilterVal] = useState({
    fromDate: null,
    toDate: null,
    betType: 'All',
  })
  const getAllBetHistory = async (filter) => {
    try {
      setLoading(true)
      const res = await getBetHistory({
        ...filter,
        offset: skip,
        limit: pageLimit,
      })
      if (res?.status === 200) {
        setBetHistory(res?.history)
        setBetHistoryCount(res?.count)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAllBetHistory()
    // eslint-disable-next-line
  }, [skip, filterVal])
  const handleDateFilter = (e) => {
    setSkip(0)
    const { name, value } = e.target
    setFilterVal({ ...filterVal, [name]: value })
  }
  const handleFilter = (val) => {
    setSkip(0)
    getAllBetHistory({ ...filterVal, betType: val })
    setFilterVal({ ...filterVal, betType: val })
  }
  useEffect(() => {
    setPageCount(Math.ceil(betHistoryCount / pageLimit))
  }, [betHistoryCount])
  const handleShowBet = () => {
    if (filterVal?.fromDate && filterVal?.toDate) {
      getAllBetHistory(filterVal)
    }
  }
  const handlePageClick = ({ selected }) => {
    setSkip(selected * pageLimit)
    setCurrentPage(selected)
  }
  return (
    <AccountLayout>
      <div id="bet-history" className="show-as-page bet-history-content">
        <div className="wrapper">
          <div className="popup-title">
            <div className="title">
              <div icon="mybets" className="icmn-icon">
                <span className="path1"></span> <span className="path2"></span>{' '}
                <span className="path3"></span>
              </div>{' '}
              <span>My Bets</span>
            </div>{' '}
            <div className="filters bet-filter">
              <div className="date-filter">
                <div className="inp-wrapper">
                  <input
                    type="date"
                    placeholder="FROM"
                    className="from-date"
                    name="fromDate"
                    onChange={handleDateFilter}
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"
                  />{' '}
                </div>{' '}
                <div className="inp-wrapper">
                  <input
                    type="date"
                    placeholder="TO"
                    className="to-date"
                    name="toDate"
                    onChange={handleDateFilter}
                  />{' '}
                  <div icon="calendar" className="icmn-icon"></div>
                </div>{' '}
                <div className="btn" onClick={() => handleShowBet()}>
                  SHOW
                </div>
              </div>{' '}
              <ul>
                <li className="" onClick={() => handleFilter('All')}>
                  All
                </li>
                {/* <li className="" onClick={()=>handleFilter("Active")}>Active</li> */}
                <li className="" onClick={() => handleFilter('Win')}>
                  Won
                </li>
                <li className="" onClick={() => handleFilter('Loss')}>
                  Lost
                </li>
              </ul>
            </div>
          </div>{' '}
          <div className="body">
            {loading ? (
              <div
                style={{ zIndex: 99, textAlign: 'center' }}
                className="table-wrapper"
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
              </div>
            ) : betHistory?.length > 0 ? (
              <table className="bets-table   table-responsive  ">
                <thead>
                  <tr>
                    <th text_key="PROFILE_TRANSACTION__DATE/TIME">Date/Time</th>
                    <th text_key="PROFILE_TRANSACTION__TYPE">Game</th>

                    <th text_key="PROFILE_TRANSACTION__WIN_LOSS">Win/Loss</th>
                    <th text_key="PROFILE_TRANSACTION__CURRENCY">Currency</th>
                    <th text_key="PROFILE_TRANSACTION__AMOUNT">Amount</th>
                    <th text_key="PROFILE_TRANSACTION__RESULT">Win/Loss</th>
                    <th text_key="PROFILE_TRANSACTION__RESULT_BEFORE">
                      Before Balance
                    </th>
                    <th text_key="PROFILE_TRANSACTION__RESULT_AFTER">
                      After Balance
                    </th>
                    {/* <th text_key="PROFILE_TRANSACTION__NAME"></th> */}
                    <th></th>
                  </tr>
                </thead>
                {betHistory &&
                  betHistory?.map((items) => (
                    <tbody>
                      <tr>
                        <th text_key="PROFILE_TRANSACTION__DATE/TIME">
                        {items?.updatedAt &&
                          `${new Date(items?.updatedAt).toLocaleDateString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            },
                          )}`}&nbsp;{`${new Date(items?.updatedAt).toLocaleTimeString(
                            'en-US',
                            {
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: true,
                            },
                          )}`}
                        </th>
                        <th text_key="PROFILE_TRANSACTION__TYPE">
                          {items?.MasterCasinoGame?.name}
                        </th>
                        <th
                          text_key="PROFILE_TRANSACTION__WIN_LOSS"
                          style={
                            items?.actionType === 'win'
                              ? { color: '#6CD9A5' }
                              : { color: '#F25C5C' }
                          }
                        >
                          {items?.actionType}
                        </th>
                        <th text_key="PROFILE_TRANSACTION__CURRENCY">
                          {items?.currencyCode || '0.00'}
                        </th>
                        <th text_key="PROFILE_TRANSACTION__AMOUNT">
                          {Math?.abs(
                            Number(
                              items?.betAmount ||
                                items?.bet_amount ||
                                items?.amount ||
                                0,
                            ),
                          )?.toFixed(2)}
                        </th>
                        <th text_key="PROFILE_TRANSACTION__RESULT">
                          {items?.actionType === 'win'
                            ? Math?.abs(
                                Number(
                                  items?.beforeBalance - items?.afterBalance,
                                ),
                              )?.toFixed(2)
                            : Math?.abs(
                                Number(
                                  items?.betAmount ||
                                    items?.bet_amount ||
                                    items?.amount ||
                                    0,
                                ),
                              )?.toFixed(2)}
                        </th>
                        <th text_key="PROFILE_TRANSACTION__RESULT_BEFORE">
                          {Number(
                            items?.actionType !== 'win'
                              ? items?.beforeBalance + (items?.betAmount || 0)
                              : items?.beforeBalance || 0,
                          ).toFixed(2)}
                        </th>
                        <th text_key="PROFILE_TRANSACTION__RESULT_AFTER">
                          {' '}
                          {Number(items.afterBalance || 0).toFixed(2)}
                        </th>
                      </tr>
                    </tbody>
                  ))}
              </table>
            ) : (
              <div className="no-data">
                <div className="material-icons icon">assignment</div>
                <div
                  className="text"
                  text_key="PROFILE_TRANSACTION__NO_TRANSACTIONS"
                >
                  Transactions Not Found
                </div>
              </div>
            )}

            <div className="d-flex justify-content-center mt-4">
              {Number(betHistoryCount || 0) > 10 ? (
                <div className="refferal-pagination  transaction-pagination">
                  <ReactPaginate
                    previousLabel={<PreArrow />}
                    breakLabel="..."
                    nextLabel={<NextArrow />}
                    // marginPagesDisplayed={-2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName={'pagination'}
                    previousLinkClassName={'previous-page'}
                    nextLinkClassName={'next-page'}
                    disabledClassName={'pagination-disabled'}
                    activeClassName={'pagination-active'}
                  />
                </div>
              ) : (
                ''
              )}{' '}
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  )
}

export default BetHistory
