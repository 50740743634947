import React from "react";
import CategoryLayout from "./index";

function GeneralTerm() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title"> General Terms</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              <strong>
                <span>Jackbit.com</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <strong>
                <span lang="GEO/KAT">Terms and Conditions</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">Last updated: </span>02.05.2022
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">1.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">GENERAL</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                These general terms and conditions (hereafter “
                <strong>Terms and Conditions</strong>”) contain all of the terms
                and conditions for use of the services (hereafter the “
                <strong>Services</strong>”) offered through the website{" "}
                <a href="http://www.jackbit.com/">
                  www.<span lang="EN-US">jackbit.com</span>
                </a>
              </span>{" "}
              <span lang="GEO/KAT">
                (hereafter the “<strong>Website</strong>”) operated by us
                (hereafter “<strong>We</strong>” ,“<strong>Company</strong>”).
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                You (hereafter “<strong>You</strong>”,”<strong>Player</strong>
                ”,”<strong>Client</strong>”) may use the Services offered
                through the Website if You open Your personal account on it
                (hereafter the “<strong>Account</strong>”).
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Before using Our Website, please read these Terms and Conditions
                carefully. By registering an Account with the Website, You
                confirm Your consent with the Terms and Conditions.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">The website </span>
              <a href="http://www.jackbit.com/">www.jackbit.com</a>{" "}
              <span lang="GEO/KAT">
                is owned and operated by <strong>Ryker B.V.</strong>
              </span>{" "}
              <span lang="GEO/KAT">(registration number: </span>154186
              <span lang="GEO/KAT">;</span> with registered Address: Abraham
              Mendez Chumaceiro Boulevard 50, Willemstad PO BOX 4750, Curaçao
              <span lang="GEO/KAT">
                ) operating through its payment agent RYKER DEVELOPMENT LIMITED
                (registration number:
              </span>{" "}
              <span>HE412288</span>
              <span lang="GEO/KAT">
                ; registered in Cyprus with registered address Griva Digeni 81,
                MARINOS COURT, 1 &amp; 2th floor, 6043 Larnaca, Cyprus). Ryker
                B.V.
              </span>{" "}
              <span lang="GEO/KAT">
                is a company registered and established under the laws of
                Curacao and licensed and regulated by the Government of Curaçao
                under the gaming license No. 1668/JAZ issued to Curaçao eGaming,
                authorized and regulated by the Government of Curacao with
                registered address: Pareraweg 45, Curacao.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">
                  2.
                  {/*  */}
                </span>
              </strong>
              <strong>
                <span lang="GEO/KAT">CHANGES TO TERMS AND CONDITIONS</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                These Terms and Conditions may be changed by the Company when
                such need occurs. We will do our best to notify our Players of
                any significant changes by email. However, we do recommend all
                Players to revisit this page regularly and check for possible
                changes.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                If any modification is unacceptable to the Client, they may
                terminate these Terms and Conditions and close their Account.
                Your continued activity in Your Account in the program following
                our posting of a change notice or new Terms and Conditions on
                our Website will constitute binding acceptance of that change.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Our failure to enforce Your strict performance of any provision
                of these Terms and Conditions will not constitute a waiver of
                Our right to subsequently enforce such provision or any other
                provision of these Terms and Conditions. No modifications,
                additions, deletions or interlineations of these Terms and
                Conditions are permitted or will be recognized by Us. None of
                our employees, officers or agents may verbally alter, modify or
                waive any provision of these Terms and Conditions.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">
                  3.
                  {/*  */}
                </span>
              </strong>
              <strong>
                <span lang="GEO/KAT">WHO CAN PLAY</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company accepts players only from those countries and
                geographic regions where online gambling is allowed by law. It
                is the player’s sole responsibility to inquire about the
                existing gambling laws and regulations of the given jurisdiction
                before placing bets on the website.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                We accept strictly adult Players (the minimal age is 18) and
                Players who have reached the age specified by the jurisdiction
                of Player’s place of residence as eligible for online gaming. It
                is the Player’s sole responsibility to inquire about the
                existing laws and regulations of the given jurisdiction
                regarding age limitations for online gambling.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                It is entirely and solely Your responsibility to enquire and
                ensure that You do not breach laws applicable to You by
                participating in the games. Depositing real funds and playing
                for real money is subject to the laws of Your country, and it is
                Your sole responsibility to abide by Your native regulations.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                We shall be entitled to refuse bets from Players who fail to
                abide by these Terms and Conditions.&nbsp;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                We reserve the right to refuse to accept a bet from any Player
                without giving a reason
              </span>
              .
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                We reserve the right to ask for the proof of age from the Player
                and limit access to the Website or suspend the Account to those
                Players who fail to meet this requirement.
              </span>
            </p>
            Name
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Internet gambling may be illegal in the jurisdiction in which
                You are located; if so, You are not authorized to use Your
                payment card to complete this transaction.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                It is solely cardholder's responsibility to know the laws
                concerning online gambling in his or her country of domicile.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Participation of minors in Services offered on this Website is
                strictly prohibited.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Users from the following countries and their territories (“
                <strong>Restricted Countries</strong>”) are not allowed to
                deposit and play real money games: Israel, France and its
                overseas territories (Guadeloupe, Martinique, French Guiana,
                Réunion, Mayotte, St. Martin, French Polynesia, Wallis and
                Futuna, New Caledonia), Netherlands, Dutch West Indies, Curacao,
                Burma, Cote D'Ivoire (Ivory Coast), Cuba, Democratic Republic of
                Congo, Iran, Iraq, Liberia, North Korea, Sudan, Syria, Zimbabwe,
                Belarus, Georgia
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company reserves the right, at its sole discretion, to
                refuse any person to register and bet on the website, to offer a
                modified coefficient, and to impose various restrictions on the
                Player (e.g. minimum coefficient, minimum adhesion, etc.), at
                the same time do not give any explanation of the reason for
                refusal / restriction.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                For the avoidance of doubt, bets placed on odds (single,
                combined or otherwise) lower than 1.30 coefficient may not be
                considered as wagered/as a placed bet at the Company’s sole
                discretion.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">
                  4.
                  {/*  */}
                </span>
              </strong>
              <strong>
                <span lang="GEO/KAT">AVAILABILITY OF GAMES</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <strong>
                <span lang="GEO/KAT">Netent Casino games terms:</span>
              </strong>
            </p>
            <p className="MsoListParagraphCxSpFirst">
              <span lang="GEO/KAT">4.1. </span>
              <span lang="GEO/KAT">
                Absolute Restriction: United States of America, United Kingdo,
                Spain, France and Italy.
              </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">4.2. </span>
              <span lang="GEO/KAT">Blacklisted Territories:</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">
                <br />
                NetEnt content shall not be served in the following territories:
                Afghanistan, Albania, Algeria, Angola, Australia, Bahamas,
                Botswana, Cambodia, Ecuador, Ethiopia, Ghana, Guyana, Hong Kong,
                Iran, Iraq, Israel, Kuwait, Laos, Myanmar, Namibia, Nicaragua,
                North Korea, Pakistan, Panama, Papua New Guinea, Philippines,
                Singapore, Sri Lanka, Sudan, Syria, Taiwan, Trinidad and Tobago,
                Tunisia, Uganda, Yemen, Zimbabwe.
              </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">4.3. </span>
              <span lang="GEO/KAT">
                Regulated Territories (only permitted to offer if receipt of a
                license from the local regulator which has been seen and
                approved by NetEnt): Belgium, Bulgaria, Czech Republic, Denmark,
                Estonia, France, Italy, Latvia, Lithuania, Mexico, Portugal,
                Romania, Serbia, Spain, Sweden, Switzerland, United Kingdom,
                United States of America.&nbsp;
              </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">&nbsp;</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">4.4. </span>
              <span lang="GEO/KAT">Branded Game Territories</span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">-</span>
              <span lang="GEO/KAT">
                Jumanji, emojiplanet, Guns &amp; Roses, Jimi Hendrix, Motörhead
                and Conan cannot be offered in the following territories:
                Afghanistan, Albania, Algeria, Angola, Australia, Bahamas,
                Botswana, Cambodia,China, Ecuador, Ethiopia, Ghana, Guyana, Hong
                Kong, Iran, Iraq, Israel, Kuwait, Laos, Myanmar, Namibia,
                Nicaragua, North Korea, Pakistan, Panama, Papua New Guinea,
                Philippines, Singapore, Sri Lanka, Sudan, Syria, Taiwan,
                Trinidad and Tobago, Tunisia, Uganda, Yemen, Zimbabwe. Belgium,
                Bulgaria, Czech Republic, Denmark, Estonia, France, Italy,
                Latvia, Lithuania Mexico, Portugal, Romania, Serbia, Spain,
                Sweden, Switzerland, United Kingdom, United States of
                America.&nbsp;
              </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">-</span>
              <span lang="GEO/KAT">
                In addition to the jurisdictions set out in paragraph 4.1,
                Planet of the Apes Video Slot must not be offered in the
                following territories: Azerbaijan, China, India, Malaysia,
                Qatar, Russia, Thailand, Turkey, Ukraine
              </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">-</span>
              <span lang="GEO/KAT">
                Vikings Video Slot must not be offered in the additional
                jurisdictions: Afghanistan, Albania, Algeria, Angola, Australia,
                Azerbaijan, Cambodia, Canada, China, Ecuador, France, Guyana,
                Hong Kong, India, Indonesia, Iran, Iraq, Israel, Kuwait, Laos,
                Malaysia, Myanmar, Namibia, North Korea, Pakistan, Papua New
                Guinea, Philippines, Qatar, Russia, Singapore, South Korea,
                Sudan, Syria, Taiwan, Thailand, Tunisia, Turkey, Ukraine, United
                States of America, Uganda.&nbsp;
              </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">-</span>
              <span lang="GEO/KAT">
                In addition to the jurisdictions set out in paragraph 4.1,
                Narcos Video Slot must not be offered in the following
                territories: China, Indonesia, South Korea. &nbsp;
              </span>
            </p>
            <p className="MsoListParagraphCxSpMiddle">
              <span lang="GEO/KAT">&nbsp;</span>
            </p>
            <p className="MsoListParagraphCxSpLast">
              <span lang="GEO/KAT">4.5. </span>
              <span lang="GEO/KAT">
                Universal Monsters (Frankenstein, the Bride of Frankenstein,
                Dracula, The Mummy, The Wolf Man, Creature from the Black Lagoon
                and The Invisible Man), can only be played in the following
                territories: Andorra, Armenia, Azerbaijan, Belarus, Bosnia and
                Herzegovina, Brazil, Georgia, Iceland, Liechtenstein, Moldova,
                Monaco, Montenegro, Norway, Russia, San Marino, Serbia,
                Switzerland, Ukraine, Croatia, Macedonia, Turkey, Austria,
                Bulgaria, Cyprus, Czech Republic, Finland, France, Germany,
                Greece, Hungary, Ireland, Latvia, Lithuania, Luxembourg, Malta,
                Netherlands, Peru, Poland, Slovakia, Slovenia, and Sweden.&nbsp;
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">5.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">ACCEPTED CURRENCIES</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The website allows playing for the following currencies:{" "}
              </span>
              EUR<span lang="GEO/KAT">,</span> USD,{" "}
              <span lang="GEO/KAT">
                BTC, ETH, LTC, XRP, BCH, USDT, XMR, DASH
              </span>
              .
            </p>
            <p className="MsoNormal">
              <strong>&nbsp;</strong>
            </p>
            <p className="MsoNormal">
              <strong>
                <span lang="GEO/KAT">&nbsp;</span>
              </strong>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">6.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">FEES AND TAXES</span>
              </strong>
              <span lang="GEO/KAT">&nbsp;</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                You are fully responsible for paying all fees and taxes applied
                to your winnings according to the laws of the jurisdiction of
                your residence.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">7.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">GAME RULES</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                By accepting these Terms and Conditions you confirm that you
                know and understand the rules of the games offered on the
                Website. It is at your discretion to familiarize Yourself with
                the theoretical payout percentage of each game.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">8.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">DISCLAIMER OF LIABILITIES</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                By accepting these Terms and Conditions You confirm Your
                awareness of the fact that gambling may lead to losing money. We
                are not liable for any possible financial damage arising from
                Your use of the Website and the Services.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                We are not liable of any hardware or software defects, unstable
                or lost Internet connection, or any other technical errors that
                may limit access to the Website or prevent any Players from
                uninterrupted play.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                In an unlikely case where a wager is confirmed or a payment is
                performed by Us in error, We reserve the right to cancel all the
                wagers accepted containing such an error, or to correct the
                mistake by re-settling all the wagers at the correct terms that
                should have been available at the time that the wager was placed
                in the absence of the error.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                If We mistakenly credit Your Account with winnings that do not
                belong to You, whether due to a technical issue, error in the
                paytables, human error or otherwise, the amount will remain Our
                property and will be deducted from Your Account. If You have
                withdrawn funds that do not belong to You prior to Us becoming
                aware of the error, the mistakenly paid amount will (without
                prejudice to other remedies and actions that may be available at
                law) constitute a debt owed by You to Us. In the event of an
                incorrect crediting, You are obliged to notify Us immediately by
                email.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">In case of complaints please contact:</span>
            </p>
            <p className="MsoNormal">
              <a
                href="mailto:support@jackbit.com"
                // target="_blank"
                rel="noopener"
              >
                support@jackbit.com
              </a>
            </p>
            <p className="MsoNormal">
              Or use Curacao eGaming license seal set on the website
              www.jackbit.com
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company, its directors, employees, partners, service
                providers:
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Do not warrant that the software or the Website is/are fit for
                their purpose;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Do not warrant that the software and Website are free from
                errors;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Do not warrant that the Website and/or games will be accessible
                without interruptions;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                shall not be liable for any loss, costs, expenses or damages,
                whether direct, indirect, special, consequential, incidental or
                otherwise, arising in relation to Your use of the Website or
                Your participation in the games.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                You hereby agree to fully indemnify and hold harmless the
                Company, its directors, employees, partners, and service
                providers for any cost, expense, loss, damages, claims and
                liabilities howsoever caused that may arise in relation to Your
                use of the Website or participation in the games.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                You acknowledge that the Company shall be the final
                decision-maker of whether You have violated these Terms and
                Conditions in a manner that results in Your suspension or
                permanent barring from participation in the Website.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">9.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">USE OF PLAYER ACCOUNT</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Only one Account is allowed per person, household or IP address.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Creating multiple Player Accounts by a single Player can lead to
                termination of all such Accounts. The Player shall not provide
                access to their Player Account or allow using the Website to any
                third party including but not limited to minors.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Any returns, winnings or bonuses which the Player has gained or
                accrued during such time as the duplicate Account was active may
                be reclaimed by Us, and Players undertake to return to Us on
                demand any such funds which have been withdrawn from the
                duplicate Account.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Website can only be used for personal purposes and shall not
                be used for any type of commercial profit.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                You are eligible for all bonuses per Jackbit account, which
                shall mean no more than one of each bonus per user, IP address,
                computer device, family, residential address, telephone number,
                credit or debit card and e-payment account, e-mail address, and
                environments where computers are shared.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">10.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">ANTI-FRAUD POLICY</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company has a strict anti-fraud policy and utilizes various
                anti-fraud tools and techniques. If the Player is suspected of
                fraudulent actions including, but not limited to:
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Participating in any type of collusion with other players;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Development of strategies aimed at gaining of unfair winnings;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Fraudulent actions against other online casinos or payment
                providers;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Chargeback transactions with a credit card or denial of some
                payments made;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">Creating two or more accounts;</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">Other types of cheating;</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                or becomes a bankrupt in the country of their residence, the
                Company reserves the right to terminate such Account and suspend
                all payouts to the Player. This decision is at sole discretion
                of the Company and the Player will not be notified or informed
                about the reasons of such actions. The Company also reserves the
                right to inform applicable regulatory bodies of the fraudulent
                actions performed by the Player.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company has zero tolerance to advantage play. Any Player who
                will try to gain advantage of casino welcome offers or other
                promotions agrees that Company reserves the right to void
                bonuses and any winnings from such bonuses, for the reasons of:
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">Use of stolen cards;</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">Chargebacks;</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Creating more than one account in order to get advantage from
                casino promotions;
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">Providing incorrect registration data;</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                any other actions which may damage the Company.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company reserves the right to close Your Account and to
                refund to You the amount on Your Account balance, subject to
                deduction of relevant withdrawal charges, at Company’s absolute
                discretion and without any obligation to state a reason or give
                prior notice.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                In order to verify Player’s Account, We require documents (ID,
                payment systems, utility bills etc.) in Latin or Cyrillic
                alphabet. In case Player doesn’t have an opportunity to provide
                documents in above-mentioned alphabets company reserves the
                right to demand video verification where Player shows his/her
                documents.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                We may request from You the verification of Your identity at any
                time and You must provide Us with any and all proof of Your
                identity. In case You fail to provide Us with the requested
                proof of identity, We reserve the right to terminate the Account
                and/or hold the funds on the Account – winnings may be
                confiscated and funds deposited by You shall be returned to You.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company reserves the right to retain payments, if suspicion
                or evidence exists of manipulation of the Website system.
                Criminal charges will be brought against any user or any other
                person(s) who has/have manipulated the Website system or
                attempted to do so. The Company reserves the right to terminate
                and/or, change any games or events being offered on the Website.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Should You become aware of any possible errors or incompleteness
                in the software, You agree to refrain from taking advantage of
                them. Moreover, You agree to report to the Company any error or
                incompleteness immediately. Should You fail to fulfill such
                obligations, the Company has a right to full compensation for
                all costs related to the error or incompleteness, including any
                costs incurred in association with the respective
                error/incompleteness and the failed notification.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Please note that if You requested a withdrawal, but the sum of
                bets made since last deposit is less than three (3) times the
                size of that deposit, We reserve the right to charge You the
                costs of transaction processing for deposit and withdrawals.
                This decision is at sole discretion of the Company.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">11.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">DEPOSITING</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Website offers a variety of payment methods. They include
                VISA and MasterCard credit and debit cards, as well as various
                alternative payment methods. Please contact our support team at{" "}
              </span>
              <span lang="GEO/KAT">
                <a
                  href="mailto:support@jackbit.com"
                  // target="_blank"
                  rel="noopener"
                >
                  support@jackbit.com
                </a>{" "}
                to inquire about the payment methods which are most favorable
                for your country of residence.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company does not accept third party payments. You must make
                deposits only from a bank account, bank cards, e-wallets or
                other payment methods that are registered in your own name. If
                we determine during the security checks that you have violated
                this condition, your winnings will be confiscated and the
                original deposit will be returned to the owner of the payment
                account. The Company is not responsible for the lost funds
                deposited from third party accounts.
              </span>
            </p>
            <p className="MsoNormal">
              The maximum amount of deposit and minimal amount of deposit
              depends on the payment method you decide to use.
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The deposited money is subject to a minimum 1x wagering prior to
                being withdrawn.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">12.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">WITHDRAWAL AND REFUND POLICY</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The minimal amount for withdrawal depends
              </span>
              <span lang="GEO/KAT">
                &nbsp;on the payment method. The maximum amount for withdrawal
                depends on the payment method you use. If the requested amount
                exceeds the limit of a particular payment system, the amount
                will be withdrawn in installments.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company reserves the right to check Your identity prior to
                processing payouts and to hold withdrawals for the time needed
                to check Your identity. In case You provide false and/or
                incomplete Personal Data, the withdrawal can be refused and/or
                the Player Account terminated, of which You will be informed by
                email.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The Company furthermore reserves the right to hold the
                withdrawals for the time needed to investigate violations of the
                Terms and Conditions and/or applicable laws on part of the
                Player.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                For Visa, the following countries are not supported: USA,
                Australia, Hong Kong, India, Indonesia, Japan, Korea, Malaysia,
                Singapore.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                For Mastercard, only the following countries are supported:
                Andorra, Austria, Belgium, Cyprus, Czech Republic, Denmark,
                Estonia, France, Germany, Gibraltar, Greece, Hungary, Iceland,
                Ireland, Italy, Latvia, Luxembourg, Malta, Monaco, Netherlands,
                Norway, San Marino, Slovakia, Slovenia, Spain, Sweden,
                Switzerland, Turkey, and United Kingdom.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Please note that even for supported countries the Company is not
                able to guarantee successful credit card payment processing in
                all cases, since banks issuing credit cards may block or reject
                such transactions at their own discretion.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The amount deducted from your credit card may be insignificantly
                higher than displayed at the time of transaction due to currency
                conversions on the side of your bank and/or the Company's
                payment processing system.
              </span>
            </p>
            <p className="MsoNormal">
              Payouts are processed within one (1) day. (Mostly, Jackbit
              approves the payouts instantly or in 1 one-hour timespan. Max time
              for a withdrawal, in any case, could be one (1) day)
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                You acknowledge that withdrawals via bank transfers can in
                exceptional cases be subject to additional charges by the
                intermediary banks. These charges remain outside the influence
                of the Company and are limited to the equivalent of 16
              </span>{" "}
              EUR<span lang="GEO/KAT">.</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                <strong>
                  The maximum withdrawal amount processed to a player is 10,000€
                  or its equivalent per 7 days period and 20,000€ or it's
                  equivalent per 30 days period
                </strong>
                &nbsp;unless otherwise specified in the Terms and Conditions of
                a specific promotion. Exceptions may be made to Players with a
                higher VIP level, if any, at the Company’s sole discretion.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                If you win more than <strong>20,000</strong>
              </span>
              <strong> EUR</strong>
              <span lang="GEO/KAT">
                , the Company reserves the right to divide the payout into
                monthly instalments of maximum <strong>20,000</strong>
              </span>
              <strong> EUR</strong>
              <span lang="GEO/KAT"> until the full amount is paid out.</span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                The maximum amount that We shall be obliged to pay to You in
                respect of any single bet or spin, including any single bonus
                round or free spin, shall be limited to and in no event shall
                exceed the amount of equivalent to{" "}
                <strong>200 000 (two hundred thousand</strong>){" "}
              </span>
              <strong>EUR</strong>
              <span lang="GEO/KAT">
                {" "}
                (the “<strong>Maximum Win Limit</strong>”). The Maximum Win
                Limit does not include any Jackpot winnings. You acknowledge and
                agree that for certain gaming services Your approved staking
                level may mean that You may have the opportunity to win above
                the Maximum Win Limit. Also, the amount of the total winning,
                including the amount above the Maximum Win Limit, may be
                deposited on Your Account. You acknowledge and agree that we
                shall have the right to cut the amount above the Maximum Win
                Limit from Your Account and pay to You only the Maximum Win
                Limit.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                All progressive jackpot wins will be paid in full.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                Finally, please keep in mind the Company is not a financial
                institution. Your account will thus not bear any interests and
                no conversion or exchange services will be offered at any time.
              </span>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                In case the existing deposits are transferred back to the Player
                due to termination of the Account, We reserve the right to
                charge the Player with the administrative fee in amount of 20%
                of the amount transferred.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">13.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">DORMANT ACCOUNTS</span>
              </strong>
            </p>
            <p className="MsoNormal">
              <span lang="GEO/KAT">
                An inactive (dormant) account is a Player Account which a player
                has not logged into or logged out of for twelve (12) consecutive
                months. If your Player Account is deemed to be inactive, the
                Company reserves the right to charge a monthly administrative
                fee of 10
              </span>{" "}
              EUR
              <span lang="GEO/KAT">
                {" "}
                or the equivalent in another currency (or the current balance of
                your account, if less) as long as the balance of your account
                remains positive.
              </span>
            </p>
            <p className="MsoNormal">
              Name
              <span lang="GEO/KAT">
                You authorize the Company to debit this fee from Your Player
                Account on the beginning of the month following the day on which
                Your Account is deemed inactive, and on the beginning of every
                subsequent month that Your Account remains inactive. The Company
                will stop deducting the fee if the Account balance is zero or if
                the account is re-activated.
              </span>
            </p>
            <p className="MsoListParagraph">
              <strong>
                <span lang="GEO/KAT">14.</span>
              </strong>
              <strong>
                <span lang="GEO/KAT">COMPLAINTS</span>
              </strong>
            </p>
            <p>
              <span lang="GEO/KAT">
                You are free to contact Our customer service team according to
                the instructions found on the Website to give Us any complaints
                regarding Our Services.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span lang="GEO/KAT">
                Complaints are handled in the support department and escalated
                in the organization of the Company in the case that support
                personnel did not solve the case immediately. You shall be
                informed about the state of the complaint to a reasonable level.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span lang="GEO/KAT">
                If the dispute is not resolved on the casino management level,
                you can contact any independent body, gaming authority or the
                licensing regulator listed on the Website.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              Please note that in order your complaint to be considered, You
              must submit it within 2 (two) months of the issue
              occurring/arising. In case Your complaint refers to an issue
              occurring/arising more than 2 (two) months ago, your complaint may
              be disregarded and You hereby declare your consent thereto.
            </p>
            <p>&nbsp;</p>
            <p>
              In the event of any dispute, you agree that the server logs and
              records shall act as the final authority in determining the
              outcome of any claim. You agree that in the unlikely event of a
              disagreement between the result that appears on your screen and
              the game server, the result that was logged on the game server
              will prevail, and you acknowledge and agree that our records will
              be the final authority in determining the terms and circumstances
              of your participation in the relevant online gaming activity and
              the results of this participation.
            </p>
            <p>
              <span lang="GEO/KAT">
                <br />
              </span>
            </p>
            <p>
              <span lang="GEO/KAT">
                We try and resolve any disagreements quickly and efficiently.
                If, however, player wishes to bring proceedings against us, you
                must do so in competent court in Curacao. These Terms and
                Conditions and the relationship between Player and{" "}
              </span>
              Jackbit
              <span lang="GEO/KAT">
                {" "}
                shall be governed by, and interpreted in accordance with the
                laws of Curacao.
              </span>
            </p>
            <p className="MsoNormal">
              <a
                href="mailto:support@jackbit.com"
                // target="_blank"
                rel="noopener"
              >
                support@jackbit.com
              </a>
            </p>
            <p>
              <span lang="GEO/KAT">
                When we wish to contact you regarding such a dispute, we will do
                so by using any of the contact details provided in your Player
                Account.
              </span>
            </p>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default GeneralTerm;
