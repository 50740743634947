import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import slider1 from "../../assets/images/home/BannerSlider/slider1.webp";
// import slider2 from "../../assets/images/home/BannerSlider/slider2.webp";
// import slider3 from "../../assets/images/home/BannerSlider/slider3.webp";
// import slider4 from "../../assets/images/home/BannerSlider/slider4.webp";
// import slider5 from "../../assets/images/home/BannerSlider/slider5.webp";
// import slider6 from "../../assets/images/home/BannerSlider/slider6.webp";
// import tabslider1 from "../../assets/images/home/BannerSlider/tabslider1.webp";
// import tabslider2 from "../../assets/images/home/BannerSlider/tabslider2.png";
// import tabslider3 from "../../assets/images/home/BannerSlider/tabslider3.png";
// import tabslider4 from "../../assets/images/home/BannerSlider/tabslider4.png";
// import tabslider5 from "../../assets/images/home/BannerSlider/tabslider5.webp";

import Category from "./category";
import { useMyContext } from "../context";
import { Link } from "react-router-dom";
import { allBanners } from "../../utility/Apis";

function BannerSlider() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const { windowWidth, handleShowTransection, handleSignupShow, forLogin } =
    useMyContext();

    const [pcBannerImages, setPcBannerImages] = useState([]);
    const [mobileBanner,setMobileBanner] =useState ([]);

    useEffect(() => {
      async function fetchBanner() {
        try {
          const res = await allBanners("Home"); 
          if (res ) {
            let newData =[] 
            newData = res?.data?.data
          let PcData = newData.filter((val)=>(
            val.plateformType === "PC" 
            ))
            let Mobiledata= newData.filter((val)=>(
              val.plateformType === "Mobile" 
            ))
            setPcBannerImages(PcData); 
            setMobileBanner(Mobiledata)

          } else {
            console.error("Failed to fetch banner images");
          }
        } catch (error) {
          console.error("Error fetching banner images:", error);
        }
      }
  
      fetchBanner(); 
    }, []); 

  return (
    <div className="banner-slider">
    <div className="banner-slider-content">
      <Slider {...settings}>
        {windowWidth <= 767 ? (
          mobileBanner.slice(0, 5).map((banner, index) => (
            <div key={index}>
                <Link to={ banner.bannerLink } target={banner.opensInNewTab ? "_blank" : "_self"} >
                  <img src={banner.bannerImage} alt={banner.bannerAlt} />
                </Link>
             
              {!forLogin ? (
                <span
                  className="btn"
                  text_key="MAIN_PAGE__REGISTER"
                  onClick={handleSignupShow}
                >
                  Register
                </span>
              ) : (
                <span
                  className="btn"
                  text_key="MAIN_PAGE__DEPOSIT"
                  onClick={() => {
                    handleShowTransection("deposit");
                  }}
                >
                  Deposit
                </span>
              )}
            </div>
          ))
        ) : (
          pcBannerImages.slice(0, 5).map((banner, index) => (
            <div key={index}>
              <Link to={ banner.bannerLink } target={banner.opensInNewTab ? "_blank" : "_self"}>
                <img src={banner.bannerImage} alt={banner.bannerAlt} />
              </Link>
            </div>
          ))
          
        )}
      </Slider> 
      
    </div>
    <Category />
  </div>
);
  
}

export default BannerSlider;
