import React, { useEffect, useRef, useState } from "react";
import backgroundMusic from "../assets/Music/background.mp3";

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const [isPlay, setIsPlay] = useState(false);

  useEffect(() => {
    const tryPlayAudio = async () => {
      if (audioRef.current) {
        audioRef.current.loop = true;
        try {
          console.log("tryPlayAudiotryPlayAudio", audioRef);
          //   await audioRef.current.autoplay();
          await audioRef.current.play();

          setIsPlay(true);
        } catch (error) {
          console.error("Audio playback failed:", error);
        }
      }
    };

    tryPlayAudio();
    const handleUserInteraction = () => {
      if (!isPlay) {
        tryPlayAudio();
        window.removeEventListener("click", handleUserInteraction);
      }
    };
    window.addEventListener("click", handleUserInteraction);
    return () => {
      window.removeEventListener("click", handleUserInteraction);
    };
  }, [isPlay]);
  console.log("isPlay", isPlay);
  return <audio ref={audioRef} preload="auto" src={backgroundMusic} />;
};

export default AudioPlayer;
