const PreArrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 33 33"
        fill="none"
      >
        <g filter="url(#filter0_i_86_2806)">
          <rect width="33" height="33" rx="8" fill="#2B2B38" />
        </g>
        <rect
          x="0.7"
          y="0.7"
          width="31.6"
          height="31.6"
          rx="7.3"
          stroke="white"
          strokeOpacity="0.04"
          strokeWidth="1.4"
        />
        <path
          d="M17 13L13 16.5L17 20"
          stroke="#666773"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_i_86_2806"
            x="0"
            y="0"
            width="33"
            height="35"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_86_2806"
            />
          </filter>
        </defs>
      </svg>
    );
  };
  export default PreArrow