import React from "react";
import CategoryLayout from "./index";

function Fairness() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title">Fairness & RNG Testing Methods</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">Fairness &amp; RNG Testing Methods</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              The Random Number Generator (RNG) is the heart of game
              functionality and player confidence to game outcomes and fairness.
              Jackbit has tested and certified various RNG’s by a reputable
              license laboratory.
            </p>
            <p className="MsoNormal">
              The source code review verified that the implementation of the RNG
              is in accordance with the technical requirements. This includes,
              but is not limited to:
            </p>
            <p className="MsoNormal">a) Identification of algorithm;</p>
            <p className="MsoNormal">
              b) Security of internal state, seeding and re-seeding, thread
              safety;
            </p>
            <p className="MsoNormal">c) Scaling for Instant game.</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              Our thorough tests of the RNG assess the statistical randomness,
              unpredictability and non-repeatability of the RNG.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              RNG’s or Random Number Generators are programs set on all of our
              Casino games, reinforcing the fact that all results remain random.
              These games are unable to be predicted or manipulated no matter
              what.
            </p>
            <p className="MsoNormal">
              The underlying concept of provable fairness is that players have
              the ability to prove and verify that their results are fair and
              unmanipulated.
            </p>
            <p className="MsoNormal">
              The commitment scheme is used to ensure that the player has an
              influence on all results generated.
            </p>
            <p className="MsoNormal">
              Company hopes to bring an easy, enjoyable, and transparent gaming
              environment right to your computer or mobile device.
            </p>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default Fairness;
