import React from "react";
import AccountLayout from "./index";
import search from "../../assets/images/account/search.svg";

function Notification() {
  return (
    <AccountLayout>
      <div className="notification-page notifications-wrapper ">
        <div className="heading">
          <div className="left">
            <h2>Notifications</h2>
            <input type="text" placeholder="Search" id="filterNotifications" />
            <img src={search} alt="search" />
          </div>
          <div className="right">
            <div
              className="mark-as-read btn disabled"
              text_key="NOTIFICATIONS__MARK_AS_READ"
            >
              Mark As Read
            </div>
          </div>
        </div>
        {/* <div className="notification-wrapper">
          <ul className="notifications"></ul>
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div> */}
        <div className="no-data">
          <div className="material-icons icon">assignment</div>
          <div className="text" text_key="NOTIFICATIONS__NO_DATA">
            No Data
          </div>
        </div>
      </div>
    </AccountLayout>
  );
}

export default Notification;
