import { useForm } from "react-hook-form";
// import back from "../../assets/images/header/auth/icon/back.svg";
import password from "../../assets/images/header/auth/icon/password43a0.svg";
import { toast } from "react-toastify";
import { resetPassword } from "../../utility/Apis.js";
import { useMyContext } from "../context/index.js";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { LeftArrow } from "./forgotPopup.jsx";
function ResetPopup({ handleResetClose, resetToken }) {
  const [loading, setLoading] = useState(false);
  const { handleLoginShow } = useMyContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const formSubmit = async (data, e) => {
    e.preventDefault();
    // if (data.new_password !== data.confirm_password) {
    //   return toast.error("Password is not match");
    // }
    try {
      setLoading(true);
      const response = await resetPassword({
        password: data?.password,
        token: resetToken,
      });
      if (response?.msg === "success") {
        setLoading(false);
        toast.success("password reset successfully");
        handleLoginShow();
        handleResetClose();
      }
    } catch (error) {
      setLoading(false);
      console.log("meesage");
    }
  };

  return (
    <div className="overlay forgetpassword-popup check-email">
      <div className="popup box">
        <span onClick={handleResetClose} className="LeftArrowBtn">
          <LeftArrow />
          {/* <img className="back" src={back} alt="back" /> */}
        </span>
        <div className="content">
          <form className="reset-password" onSubmit={handleSubmit(formSubmit)}>
            <ul className="fields">
              <li>
                <h2 text_key="RESET_PASSWORD__RESET_PASSWORD">
                  Reset Password
                </h2>
              </li>
              <li>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="New_Password"
                  className={`${
                    errors?.new_password?.message ? "dirty invalid" : ""
                  }`}
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':".,/<>?\|`~])(?=.*\d)(?=.{8,})/,
                      message:
                        "Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long",
                    },
                  })}
                />
                <img src={password} alt="password" />
              </li>
              <li
                className="err-text"
                field="UserName"
                style={{ textAlign: "start" }}
              >
                {errors?.password?.message}
              </li>
              {/* <li>
              <input
                type="password"
                name="confirm_password"
                placeholder="Confirm_Password"
                className={`${errors?.confirm_password?.message?"dirty invalid":""}`}
                // className={`${errors?.confirm_password?.message ? "dirty invalid":""}`}
                {...register("confirm_password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      // eslint-disable-next-line
                      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':".,/<>?\|`~])(?=.*\d)(?=.{8,})/,
                    message:
                      "Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long",
                  },
                })}
              />
              <img src={password} alt="password" />
            </li> */}
              <li
                className="err-text"
                field="UserName"
                style={{ textAlign: "start" }}
              >
                {errors?.confirm_password?.message}
              </li>
              <li>
                <button
                  className="btn"
                  text_key="RESET_PASSWORD__SEND_INSTRUCTIONS"
                  type="submit"
                >
                  {loading ? <Spinner /> : "Reset Password"}
                </button>
                {/* {<p style={{ color: "red", paddingTop: "15px" }}>{error}</p>} */}
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPopup;
