import React, { useState } from "react";
import copy from "../../assets/images/icons/copy-icon.svg";
import {
  // NetWorkData,
  coinData,
  currency,
} from "../../utility/transectionFormDetails.js";
import "./style.css";
import {
  checkOut,
  getTransectonDetails,
  withDrawAmount,
} from "../../utility/Apis.js";
import { toast } from "react-toastify";
import { useMyContext } from "../context/index.js";
import { Spinner } from "react-bootstrap";
// import QRCodeGenerator from "../../utility/CustomQrCodeGenerator.jsx";
import { QRCodeSVG } from "qrcode.react";

function TransectionPopup({ handleHideTransection, activeTab }) {
  let [loading, setLoading] = useState(false);
  const { userDetails, setUserDetails } = useMyContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isNetwork, setIsNetwork] = useState(false);
  // const [isBuy, setIsBuy] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [isWithdrawNetwork, setIsWithdrawNetwork] = useState(false);
  const [walletActiveTab, setWalletActiveTab] = useState(activeTab);
  // state for handle Account Currency data and images
  const [name, setName] = useState("Select Account Currency");
  const [image, setImage] = useState(null);
  // state for handle Account Currency withdraw data and images
  const [withdrawname, setwithdrawName] = useState("Select Account Currency");
  const [withdrawimage, setwithdrawImage] = useState(null);
  const [withdrawsym, setWithdrawsym] = useState("");
  const [networkValue, setIsNetworkValue] = useState("Choose Network");
  console.log(networkValue);
  const [addressValue, setAddressValue] = useState(null);

  //manage currency data
  const [currencyName, setCurrencyName] = useState("Select");
  const [currencyImage, setCurrencyImage] = useState(null);
  const [currencyValue, setCurrencyValue] = useState("");
  const [withDrawData, setWithdrawData] = useState({
    amount: "",
    address: "",
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const NetworktoggleDropdown = () => {
    setIsNetwork(!isNetwork);
  };
  console.log(NetworktoggleDropdown);

  // const toggleBuyDropdown = () => {
  //   setIsBuy(!isBuy);
  // };

  const togglePayDropdown = () => {
    setIsPay(!isPay);
  };

  const toggleWithdrawDropdown = () => {
    setIsWithdraw(!isWithdraw);
  };

  const toggleNetworkDropdown = () => {
    setIsWithdrawNetwork(!isWithdrawNetwork);
  };
  console.log(toggleNetworkDropdown);

  const getName = (data, value) => {
    // console.log("data , value------------>", data, value);

    if (value.value === "deposit") {
      setName(`${data.coin}`);
      setImage(data?.icon);
    } else if (value.value === "withdraw") {
      setwithdrawName(`${data.coin}`);
      setwithdrawImage(data?.icon);
      setWithdrawsym(data?.sym);
    } else if (value.value === "buyCrypto") {
      setCurrencyName(data.name);
      setCurrencyImage(data?.icon);
    }
  };
  // console.log("withdrawsym============?>", withdrawsym);

  // ======Deposit and withdrawal api call here================================
  const callApiDeposit = async (data, value) => {
    if (value.value === "deposit") {
      setIsNetworkValue(data.coin);
      try {
        setLoading(true);
        const res = await getTransectonDetails({ currencyCode: data.sym });
        // console.log("res==============>", res);
        if (res?.walletDetail) {
          setLoading(false);
          setAddressValue(res?.walletDetail?.address);
        } else {
          toast.error(res?.message, {
            autoClose: 2000,
            position: "top-right",
            toastId: "address-success",
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else if (value.value === "withdraw") {
      if (withDrawData.amount <= 0)
        return toast.error("Invalid Amount", {
          toastId: "Invalid-amount",
        });
      const newData = {
        amount: withDrawData.amount,
        address: withDrawData.address,
        currencyCode: withdrawsym,
      };
      try {
        const res = await withDrawAmount(newData);
        if (res?.status === 200) {
          toast.success("withdraw successfull", {
            toastId: "withdraw_amount",
          });
          setUserDetails({
            ...userDetails,
            cryptoWallet: {
              ...userDetails.cryptoWallet,
              [res?.blockchain]: res?.currentBalance,
            },
          });
        } else {
          toast.error(res?.message, {
            toastId: "withdraw_error",
          });
        }
        // console.log("res============>", res);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //open payment browser like modal

  function openModal(url, windowName, width, height) {
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    // Open a new window with specified features
    const modalWindow = window.open(
      url,
      windowName,
      `width=${width},height=${height},left=${left},top=${top},modal=yes,centerscreen=yes,chrome=yes`
    );

    return modalWindow;
  }

  // buy crypto api call here===========================
  const handleBuyCrypto = async () => {
    if (!currencyValue) return;
    try {
      const res = await checkOut({
        amount: currencyValue,
        currency: currencyName,
      });
      // console.log("res======>", res);
      if (res?.status === 200) {
        // window.open(res?.data?.hosted_url);
        const modalWindow = openModal(
          res?.data?.hosted_url,
          "ModalWindow",
          600,
          800
        );
        console.log(modalWindow);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="transection-popup">
      <div className="overlay payments-popup">
        <div className="popup box">
          {/* <span className="close">
            <img src={closeIcon} alt="closeIcon" />
          </span> */}
          <div className="material-icons close" onClick={handleHideTransection}>
            close
          </div>
          <div className="btn-wrapper">
            <div
              data-page="deposit"
              //   className="btn active"
              className={`btn ${
                walletActiveTab === "deposit" ? "active" : "solid"
              }`}
              onClick={() => setWalletActiveTab("deposit")}
              text_key="PAYMENTS__DEPOSIT"
            >
              Deposit
            </div>
            {/* <div
              data-page="byCrypto"
              className={`btn ${
                walletActiveTab === "byCrypto" ? "active" : "solid"
              }`}
              onClick={() => setWalletActiveTab("byCrypto")}
              text_key="PAYMENTS__BUY_CRYPTO"
            >
              Buy Crypto
            </div> */}
            <div
              data-page="withdraw"
              //   className="btn solid"
              className={`btn ${
                walletActiveTab === "withdraw" ? "active" : "solid"
              }`}
              onClick={() => setWalletActiveTab("withdraw")}
              text_key="PAYMENTS__WITHDRAW"
            >
              Withdraw
            </div>
          </div>
          <div className="section-wrapper">
            <div
              // className="content"
              className={`content ${
                walletActiveTab === "deposit" ? "" : "hidden"
              }`}
              data-page="deposit"
            >
              <form>
                <div className="row">
                  <div className="item">
                    <span text_key="PAYMENTS__ACCOUNT_CURRENCY">
                      Account Currency
                    </span>
                    <div
                      className="select"
                      name="currencySelect"
                      onClick={toggleDropdown}
                    >
                      <div
                        className="select-styled "
                        text_key="PAYMENTS__SELECT_ACCOUNT_CURRENCY"
                      >
                        {/* Select Account Currency */}
                        {name}
                        <img
                          src={image}
                          style={{ height: "18px", marginLeft: "4px" }}
                          alt=""
                        />
                      </div>

                      {isOpen && (
                        <ul className="select-options">
                          {coinData?.map((item) => (
                            <li
                              key={item.coin}
                              pci={item.pci}
                              rel={item.rel}
                              onClick={() => {
                                getName(item, { value: "deposit" });
                                callApiDeposit(item, { value: "deposit" });
                              }}
                            >
                              <div className="icon-wrapper">
                                <img src={item.icon} alt="icon" />
                              </div>{" "}
                              {item.coin}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="item">
                    <span text_key="PAYMENTS__CHOOSE_NETWORK">
                      Choose Network
                    </span>
                    <div
                      className="select"
                      name="networkSelect"
                      onClick={NetworktoggleDropdown}
                    >
                      <div
                        className="select-styled active"
                        text_key="PAYMENTS__CHOOSE_NETWORK"
                      >
                        {networkValue}
                      </div>
                      {isNetwork && (
                        <ul class="select-options">
                          {NetWorkData.map((item) => (
                            <li
                              key={item.coin}
                              pci={item.pci}
                              rel={item.rel}
                              onClick={() =>
                                callApiDeposit(item, { value: "deposit" })
                              }
                            >
                              {item?.coin}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="item">
                    <span text_key="PAYMENTS__DEPOSIT_ADDRESS">
                      Deposit Address
                    </span>
                    <div className="inp-wrapper">
                      <input
                        type="text"
                        name="depositAddress"
                        value={addressValue}
                        placeholder_key="PAYMENTS__WALLET_ADDRESS"
                        readonly="readonly"
                        placeholder="Wallet Address"
                      />
                      {!loading ? (
                        // addressValue ? (
                        <div
                          style={{ textAlign: "center", paddingTop: "15px" }}
                        >
                          {addressValue && (
                            <QRCodeSVG value={addressValue || ""} size={160} />
                          )}
                        </div>
                      ) : (
                        <div style={{ zIndex: 99, textAlign: "center" }}>
                          <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row" data-type="tag">
                  <div className="item">
                    <span text_key="PAYMENTS__TAG"></span>
                    <div className="inp-wrapper">
                      <input
                        type="text"
                        name="destinationTag"
                        placeholder_key="PAYMENTS__DESTINATION_TAG"
                        readonly="readonly"
                      />
                      <img
                        className="copy"
                        src="../assets/images/payments/icons/copy43a0.svg?v3"
                        alt="copy"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="qr-code"></div>
              <div className="description">
                <span text_key="PAYMENTS__NOTE">Please read</span>
                <div className="text-wrapper">
                  <ul>
                    <li>
                      <p text_key="PAYMENTS__SEND_ONLY">Send only</p>
                      <span text_key="PAYMENTS__CRYPTOCURRENCY">
                        cryptocurrency
                      </span>
                      <p text_key="PAYMENTS__TO_THIS_DEPOSIT_ADDRESS">
                        to this deposit address.
                      </p>
                    </li>
                    <li>
                      <p text_key="PAYMENTS__ENSURE_THE_NETWORK_IS">
                        Ensure the network is -
                      </p>
                      <span text_key="PAYMENTS__BLOCKCHAIN_NETWORK">
                        blockchain network
                      </span>
                    </li>
                    <li>
                      <p text_key="PAYMENTS__MAKE_SURE_YOU_DO_NOT_SEND">
                        Make sure not to send
                      </p>
                      <span text_key="PAYMENTS__NFT">NFT</span>
                      <p text_key="PAYMENTS__TO_THE_ADDRESS">to the address.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* =====================Withdraw amount starting here==============================  */}
            <div
              // className="content "
              className={`content ${
                walletActiveTab === "withdraw" ? "" : "hidden"
              }`}
              data-page="withdraw"
            >
              <form>
                <div className="steps-wrapper">
                  <div className="step first-step">
                    <div className="row">
                      <div className="item">
                        <span text_key="PAYMENTS__ACCOUNT_CURRENCY">
                          Account Currency
                        </span>
                        <div
                          className="select"
                          name="currencySelect"
                          onClick={toggleWithdrawDropdown}
                        >
                          <div
                            className="select-styled active"
                            text_key="PAYMENTS__SELECT_ACCOUNT_CURRENCY"
                          >
                            {/* Select Account Currency */}
                            {withdrawname}
                            <img
                              src={withdrawimage}
                              style={{ height: "18px", marginLeft: "4px" }}
                              alt=""
                            />
                          </div>
                          {isWithdraw && (
                            <ul className="select-options">
                              {coinData?.map((item) => (
                                <li
                                  pci={item.pci}
                                  rel={item.rel}
                                  onClick={() =>
                                    getName(item, { value: "withdraw" })
                                  }
                                >
                                  <div className="icon-wrapper">
                                    <img src={item.icon} alt="icon" />
                                  </div>{" "}
                                  {item.coin}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="item" name="balanceField">
                        <span text_key="PAYMENTS__BALANCE">Amount in USD</span>
                        {/* <div className="balance-field"> */}
                        {/* <span className="amount"></span> */}
                        {/* <div className="currency"> */}
                        <input
                          className="amount"
                          type="number"
                          value={withDrawData.amount}
                          onChange={(e) =>
                            setWithdrawData({
                              ...withDrawData,
                              amount: e.target.value,
                            })
                          }
                        />
                        {/* </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="item">
                        <span text_key="PAYMENTS__CHOOSE_NETWORK">
                          Choose Network
                        </span>
                        <div
                          className="select"
                          name="networkSelect"
                          onClick={toggleNetworkDropdown}
                        >
                          <div
                            className="select-styled active"
                            text_key="PAYMENTS__CHOOSE_NETWORK"
                          >
                            {networkValuewithdraw}
                          </div>
                          {isWithdrawNetwork && (
                            <ul class="select-options">
                              {NetWorkData.map((item) => (
                                <li
                                  key={item.coin}
                                  pci={item.pci}
                                  rel={item.rel}
                                  onClick={() =>
                                    callApiDeposit(item, { value: "withdraw" })
                                  }
                                >
                                  {item?.coin}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="item">
                        <span text_key="PAYMENTS__DEPOSIT_ADDRESS">
                          {/* Deposit Address */}
                          To
                        </span>
                        <div className="inp-wrapper">
                          <input
                            type="text"
                            name="depositAddress"
                            value={withDrawData.address}
                            placeholder="Wallet Address"
                            // placeholder_key="PAYMENTS__WALLET_ADDRESS"
                            onChange={(e) =>
                              setWithdrawData({
                                ...withDrawData,
                                address: e.target.value,
                              })
                            }
                          />
                          <img
                            className="copy"
                            src={copy}
                            alt="copy"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-content"></div>
                    <div className="description">
                      <span text_key="PAYMENTS__NOTE">Please read</span>
                      <div className="text-wrapper">
                        <ul>
                          <li>
                            <p text_key="PAYMENTS__SEND_ONLY">Send only</p>
                            <span text_key="PAYMENTS__CRYPTOCURRENCY">
                              cryptocurrency
                            </span>
                            <p text_key="PAYMENTS__TO_THIS_DEPOSIT_ADDRESS">
                              to this deposit address.
                            </p>
                          </li>
                          <li>
                            <p text_key="PAYMENTS__ENSURE_THE_NETWORK_IS">
                              Ensure the network is -
                            </p>
                            <span text_key="PAYMENTS__BLOCKCHAIN_NETWORK">
                              blockchain network
                            </span>
                          </li>
                          <li>
                            <p text_key="PAYMENTS__MAKE_SURE_YOU_DO_NOT_SEND">
                              Make sure not to send
                            </p>
                            <span text_key="PAYMENTS__NFT">NFT</span>
                            <p text_key="PAYMENTS__TO_THE_ADDRESS">
                              to the address.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="action-wrapper">
                  {/* <div className="btn solid done">
                    <span text_key="PAYMENTS__SUCCESS"> Success</span>
                    <img src={success} alt="success" />
                  </div> */}
                  <div
                    className="btn confirm"
                    text_key="PAYMENTS__CONFIRM"
                    onClick={() =>
                      callApiDeposit(withDrawData, { value: "withdraw" })
                    }
                  >
                    Confirm
                  </div>
                </div>
                <div className="err-text"></div>
              </form>
            </div>
            <div
              // className="content "
              className={`content byCrypto-popup ${
                walletActiveTab === "byCrypto" ? "" : "hidden"
              }`}
              data-page="byCrypto"
            >
              {/* <div className="row paymentMethods">
                <div className="item">
                  <span>CHOOSE PAYMENT METHOD</span>
                  <div className="select" name="paymentMethod">
                    <div className="select-styled active">changelly</div>
                    <ul className="select-options">
                      <li rel="changelly">changelly</li>
                      <li rel="lunu">lunu</li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <form data-method="changelly">
                {/* <div className="row">
                  <div className="item">
                    <span text_key="PAYMENTS__BUY">Buy</span>
                    <div className="inline">
                      <div className="inp-wrapper">
                        <input
                          type="text"
                          name="cryptoCurrencyAmount"
                          defaultValue="Buy"
                        />
                      </div>
                      <div class="select" name="cryptoCurrency">
                        <div
                          class="select-styled"
                          value="332"
                          onClick={toggleBuyDropdown}
                        >
                          <div class="icon-wrapper">
                            <img src={icon2} alt="icon" />
                          </div>{" "}
                          BTC
                        </div>

                        {isBuy && (
                          <ul class="select-options">
                            <li rel="332">
                              <div class="icon-wrapper">
                                <img src={icon2} alt="icon" />
                              </div>{" "}
                              BTC
                            </li>
                            <li rel="334">
                              <div class="icon-wrapper">
                                <img src={icon3} alt="icon" />
                              </div>{" "}
                              ETH
                            </li>
                            <li rel="336">
                              <div class="icon-wrapper">
                                <img src={icon8} alt="icon" />
                              </div>{" "}
                              LTC
                            </li>
                            <li rel="345">
                              <div class="icon-wrapper">
                                <img src={icon10} alt="icon" />
                              </div>{" "}
                              USDT.ERC20
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  <div className="item">
                    <span>
                      <span text_key="PAYMENTS__PAY_WITH"></span>
                      <span name="fiatCurrency">Buy</span>
                    </span>
                    <div className="inline">
                      <div className="inp-wrapper">
                        <input
                          placeholder="amount"
                          type="text"
                          name="fiatCurrencyAmount"
                          // value={currencyValue}
                          onChange={(e) => setCurrencyValue(e.target.value)}
                        />
                      </div>
                      <div className="select" name="fiatCurrency">
                        <div
                          className="select-styled active"
                          onClick={togglePayDropdown}
                        >
                          {currencyName}
                          <img
                            src={currencyImage}
                            style={{ height: "18px", marginLeft: "4px" }}
                            alt=""
                          />
                        </div>
                        {isPay && (
                          <ul className="select-options">
                            {currency?.map((item) => (
                              <li
                                key={item.name}
                                rel={item.rel}
                                onClick={() =>
                                  getName(item, { value: "buyCrypto" })
                                }
                              >
                                <div className="icon-wrapper">
                                  <img src={item.icon} alt="icon" />
                                </div>{" "}
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn solid visible changelly-deposit">
                  <span text_key="PAYMENTS__BUY" onClick={handleBuyCrypto}>
                    Buy
                  </span>
                </div>
                <div className="error changelly-error"></div>
              </form>
              {/* <form data-method="lunu">
                <div className="row">
                  <div className="item">
                    <span>
                      <span text_key="PAYMENTS__BUY"></span> (EUR)
                    </span>
                    <div className="inp-wrapper">
                      <input type="text" name="amount" />
                    </div>
                  </div>
                </div>
                <div className="btn solid visible lunu-deposit">
                  <span text_key="PAYMENTS__BUY"></span>
                </div>
                <div className="loader"></div>
                <div className="error lunu-error"></div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransectionPopup;
