import casino from "../../assets/images/header/search/casino.svg";
import livecasino from "../../assets/images/header/search/live-casino.svg";
import prematch from "../../assets/images/header/search/prematch.svg";
import providers from "../../assets/images/header/search/providers.svg";
import miniGames from "../../assets/images/header/search/mini-games.svg";
import esports from "../../assets/images/header/search/esports.svg";
// import closeIcon from "../../assets/images/header/search/close.svg";
import { Spinner } from "react-bootstrap";
import play from "../../assets/images/casinogame/gamecard/play.svg";
// import { useState } from "react";

function SearchContent({ allFilteredGames, loading, wrapperRef, handleGame }) {
  // const [showFav, setShowFav] = useState();
  // const handleChangeFav = () => {
  //   setShowFav(!showFav);
  // };

  return (
    <>
      <div className="big-search-wrapper visible">
        <div className="middle">
          <div className="container">
            <ul className="categories">
              <li class="all active box">
                <span text_key="SEARCH__ALL">ALL</span>
              </li>
              <li className="casino disabled box">
                <img src={casino} alt="slots" />
                <span text_key="SEARCH__CASINO">CASINO</span>
                <p className="count">0</p>
              </li>
              <li className="livecasino disabled box">
                <img src={livecasino} alt="live-casino" />
                <span text_key="SEARCH__LIVE_CASINO">LIVE_CASINO</span>
                <p className="count">0</p>
              </li>
              <li className="livecasino disabled box">
                <img src={prematch} alt="live-casino" />
                <span text_key="SEARCH__LIVE_CASINO">SPORTS</span>
                <p className="count">0</p>
              </li>
              <li className="livecasino disabled box">
                <img src={esports} alt="live-casino" />
                <span text_key="SEARCH__LIVE_CASINO">E-SPORTS</span>
                <p className="count">0</p>
              </li>
              <li className="livecasino disabled box">
                <img src={miniGames} alt="live-casino" />
                <span text_key="SEARCH__LIVE_CASINO">MINI-GAMES</span>
                <p className="count">0</p>
              </li>
              <li className="livecasino disabled box">
                <img src={providers} alt="live-casino" />
                <span text_key="SEARCH__LIVE_CASINO">PROVIDERS</span>
                <p className="count">0</p>
              </li>
            </ul>
            <div className="search-content">
              <ul>
                <li className="history full-width box" data-view="first">
                  <p className="title" text_key="SEARCH__HISTORY">
                    Games
                  </p>
                  <div
                    className={`${
                      allFilteredGames.length > 0
                        ? "search_history_game"
                        : "game-not-found"
                    }`}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                        variant="light"
                      />
                    ) : allFilteredGames && allFilteredGames.length > 0 ? (
                      allFilteredGames?.map((game) => {
                        return (
                          <li
                            key={game.masterCasinoGameId}
                            gameid={game?.masterCasinoGameId}
                            category={game?.masterGameSubCategoryId}
                          >
                            <div className="bg">
                              <img
                                src={game?.thumbnailUrl}
                                alt="category-games"
                              />
                              <div className="slot-hover">
                                <span onClick={() => handleGame(game)}>
                                  <img
                                    className="play"
                                    src={play}
                                    alt="play icon"
                                  />
                                </span>
                                <div className="star-wrapper">
                                  <span
                                    className="material-icons"
                                    // onClick={handleChangeFav}
                                  >
                                    {true ? "favorite_border" : "favorite"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <h6 className="pt-2">
                              {game?.name?.length > 20
                                ? game?.name?.substring(0, 20) + "..."
                                : game?.name}
                            </h6>
                          </li>
                        );
                      })
                    ) : (
                      <h1>No Games Found</h1>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchContent;
