import React from "react";
import CategoryLayout from "./index";

function Faq() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title">F.A.Q (Frequently Asked Questions)</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              <strong>1. How can we contact you?</strong>
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              You can&nbsp;contact us using the following means:
            </p>
            <p className="MsoNormal">
              Send us an email via our support email address&nbsp;
              <a href="mailto:support@xbet225.com">support@jackbit.com</a>{" "}
              &nbsp;
            </p>
            <p className="MsoNormal">
              Connect directly to our support managers via Live Chat.
            </p>
            <p className="MsoNormal">
              In case of any questions or emergencies you can always reach out
              to the staff of Jackbit without any delay or problems. On
              whichever page you are on Jackbit you can always see the “Contact”
              button in the left corner of the screen.&nbsp;
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              By pushing the “Contact” you will be redirected to new section of
              the website where you will have two contact options. First of them
              is to write an official email. Jackbit’s email is visible all the
              time and you can send a message there anytime you want. After you
              type the message only thing left to do is to input the Email where
              Jackbit’s representatives will contact you and you must also write
              a small summary of your issue in the Subject line. After you will
              fill in the fields with the necessary information just click on
              the red “Send” button and our representatives will contact you as
              soon as they review your message.&nbsp;
            </p>
            <p className="MsoNormal">
              If your issue is very important and you can’t wait for our
              representatives to contact you via email you can always use our
              Live Chat option. You can access the Live Chat feature from the
              Contact button like with sending an email or you can simply scroll
              down the website and you will see the Live Chat option below the
              Contact Us button. After pushing the Live Chat button, a window
              will pop up where the official representative of jackbit.com will
              join the chat and you can discuss everything with him/her.&nbsp;
            </p>
            <p className="MsoNormal">
              In case you want to make a commercial offer to jackbit.com or if
              you are an affiliate looking for partnership it is better to use
              the email that is provided below, under the Contact Us button.
              Nevertheless, you can also use general email for that.&nbsp;
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>2. How To Register?&nbsp;</strong>
            </p>
            <p className="MsoNormal">
              On jackbit.com&nbsp;registration&nbsp;is easy and quick. In order
              to join our community, all you have to do is simply click on the
              red “Sign Up” button in the right corner of the main page. After
              this you will be redirected to another page where you can input
              your personal details. Three separate fields will be shown. Each
              of these fields are specifically designed for you to input your
              information such as Email address, Username and Password of
              course.&nbsp;
            </p>
            <p className="MsoNormal">
              In the Email field you must input your valid email address to keep
              track of any interesting promotions that Jackbit might offer
              you.&nbsp;
            </p>
            <p className="MsoNormal">
              In the Username field it is important to write a name that you
              like or associate with something. Username can’t be changed and it
              represents you as a player.&nbsp;
            </p>
            <p className="MsoNormal">
              Password field is one of the most important components. You must
              choose your password that will be used to access your account
              carefully. In order to ensure maximum security, Jackbit requires
              the password to be at least eight characters long including one
              uppercase letter and at least one alphanumeric character.&nbsp;
            </p>
            <p className="MsoNormal">
              Once you fill all of the above-mentioned fields, don’t forget to
              click on the small box below that says “I accept Terms &amp;
              Conditions and Privacy Policy”. This way you agree to the terms of
              jackbit.com and can finish the registration by clicking the red
              “Finish” button below.&nbsp;
            </p>
            <p className="MsoNormal">
              If everything is entered correctly you will access your new
              account, in case of any problems you will see red text below the
              fields explaining which field was filled incorrectly.&nbsp;
            </p>
            <p className="MsoNormal">
              After finishing the registration form you can access your account
              and start playing with cryptocurrency. In order to gain full
              access to Jackbit’s products and use other payment methods apart
              from cryptocurrency, you can always finish setting up your profile
              so that jackbit.com can officially verify your account.&nbsp;
            </p>
            <p className="MsoNormal">
              In order to verify your account simply click on the “My Account”
              button in the right corner of the page and go to “Personal
              Information” section.
            </p>
            <p className="MsoNormal">
              In this section you will see some information regarding your
              account. Naturally Username, Password and Email will be already
              filled. To verify your account, you have to finish all other
              fields such as First name, Last name, Date of Birth, Mobile
              Number, Country, City and Address.&nbsp;
            </p>
            <p className="MsoNormal">
              After all this information is filled you will be able to fully
              enjoy your time on jackbit.com.
            </p>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default Faq;
