import React from "react";
// import arrowdown from "../../assets/images/icons/arrow-down.svg";
import { useMyContext } from "../context";

function GameSearch({
  allGamesData: gamesData,
  page,
  setLoading,
  providers,
  getProviderDetails,
  // allGamesByCategory,
  handlechange,
}) {
  const { windowWidth } = useMyContext();
  // const [activeItem, setActiveItem] = useState("");
  // const [searchProvider, setSearchProvider] = useState("");
  // const handleOptionClick = (item) => {
  //   setActiveItem(item?.name);
  //   setSearchProvider(item?.name);
  // };

  return (
    <div className="filter-content">
      {
        windowWidth <= 991
        // &&
        // <GameSlot />
      }
      <div className="filter-wrapper">
        <div className="search-wrapper">
          <input
            type="text"
            placeholder_key="CASINO__SEARCH_GAMES"
            className="search-game"
            placeholder="Search Games"
            onChange={handlechange}
          />
          <span icon="search" className="material-icons icon"></span>
        </div>
        <div className="clear">
          <span className="material-icons icon">delete_sweep</span>
        </div>
        {/* <div className="dropdown-cont" tabIndex="0">
          <div className="custom-select provider-filter" id="">
            <input
              type="text"
              name="searchProvider"
              placeholder_key="CASINO__ALL_PROVIDERS"
              placeholder="All Providers"
              value={searchProvider}
              onChange={(e) => setSearchProvider(e.target.value)}
            />
            <img className="drop-arrow" src={arrowdown} alt="arrow-down" />
          </div>
          <div className="options-wrapper">
            <ul id="custom-select-opt">
              {providers?.map((item, index) => (
                <li
                  key={item?.masterCasinoProviderId}
                  value={item.value}
                  className={item.value === activeItem ? "active" : ""}
                  onClick={() => handleOptionClick(item)}
                >
                  <span
                    className="provider-name"
                    onClick={() => getProviderDetails(item)}
                  >
                    {item?.name}
                  </span>
                  <span className="counter">{index + 1}</span>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default GameSearch;
