import React from "react";
import arrow from "../../assets/images/header/search/arrow-left.svg";
import mic from "../../assets/images/header/search/mic.svg";

function ResposiveSearch({ handleResponsiveHide, respsearch,gameSubCategory }) {
  return (
    <div className={`big-search-wrapper ${respsearch ? "visible" : ""}`}>
      <div className="search-bar">
        <div className="back box" onClick={handleResponsiveHide}>
          <img src={arrow} alt="arrow left" />
        </div>
        <input
          autoComplete="off"
          type="text"
          id="search"
          placeholder="Search requires at least 3 characters."
        />
        <div className="voice box">
          <img src={mic} alt="mic" />
        </div>
      </div>
      <div className="middle">
        <ul className="categories">
          <li className="all active ">
            <span text_key="SEARCH__ALL">All</span>
          </li>
          {}
          <li className="casino disabled ">
            <span text_key="SEARCH__CASINO">Casino</span>
            <p className="count">0</p>
          </li>
          <li className="livecasino disabled ">
            <span text_key="SEARCH__LIVE_CASINO">Live Casino</span>
            <p className="count">0</p>
          </li>
          <li className="sports disabled ">
            <span text_key="SEARCH__SPORTS">Sports</span>
            <p className="count">0</p>
          </li>
          <li className="esport disabled ">
            <span text_key="SEARCH__E-SPORT">E-Sports</span>
            <p className="count">0</p>
          </li>
          <li className="minigames disabled ">
            <span text_key="SEARCH__MINI_GAMES">Mini-Games</span>
            <p className="count">0</p>
          </li>
          <li className="providers disabled ">
            <span text_key="SEARCH__PROVIDERS">Providers</span>
            <p className="count">0</p>
          </li>
        </ul>
        <div className="search-content">
          <ul>
            <li className="history full-width box" data-view="first">
              <p className="title" text_key="SEARCH__HISTORY">
                HISTORY
              </p>
              <div className="items" id="history"></div>
            </li>
            <li
              className="games-container box"
              data-section="casino, livecasino, minigames"
            >
              <p className="title" text_key="SEARCH__GAMES">
                GAMES
              </p>
              <div className="scroll-wrapper">
                <div className="items" id="games"></div>
              </div>
            </li>
            <li className="providers-container box" data-section="providers">
              <p className="title" text_key="SEARCH__PROVIDERS">
                Providers
              </p>
              <div className="scroll-wrapper">
                <div className="items" id="vendors"></div>
              </div>
            </li>
            <li
              className="sport-container full-width box"
              data-section="sports"
            >
              <p className="title" text_key="SEARCH__SPORT">
                SPORT
              </p>
              <div className="scroll-wrapper">
                <div id="sportsbook-matches"></div>
              </div>
            </li>
            <li
              className="esport-container full-width box"
              data-section="esport"
            >
              <p className="title" text_key="SEARCH__ESPORT">
                ESPORT
              </p>
              <div className="scroll-wrapper">
                <div id="esport-matches"></div>
              </div>
            </li>
            <li
              className="recomended-container full-width box"
              data-view="first"
            >
              <p className="title" text_key="SEARCH__RECOMENDED">
                Recommended
              </p>
              <div className="swiper-container">
                <div className="swiper-wrapper"></div>
                <div className="pagination-wrapper">
                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ResposiveSearch;
