import React from "react";
import CategoryLayout from "./index";

function AboutUs() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title"> About Us</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              <span lang="GEO/KAT">The website www</span>.jackbit.com{" "}
              <span lang="GEO/KAT">
                is owned and operated by <strong>Ryker B.V.</strong>
              </span>{" "}
              <span lang="GEO/KAT">(registration number: </span>154186
              <span lang="GEO/KAT">;</span> with registered Address: Abraham
              Mendez Chumaceiro Boulevard 50, Willemstad PO BOX 4750, Curaçao
              <span lang="GEO/KAT">
                ) operating through its payment agent RYKER DEVELOPMENT LIMITED
                (registration number:
              </span>{" "}
              <span>HE412288</span>
              <span lang="GEO/KAT">
                ; registered in Cyprus with registered address Griva Digeni 81,
                MARINOS COURT, 1 &amp; 2th floor, 6043 Larnaca, Cyprus). Ryker
                B.V.
              </span>{" "}
              <span lang="GEO/KAT">
                is a company registered and established under the laws of
                Curacao and licensed and regulated by the Government of Curaçao
                under the gaming license No. 1668/JAZ issued to Curaçao eGaming,
                authorized and regulated by the Government of Curacao with
                registered address: Pareraweg 45, Curacao.
              </span>
            </p>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default AboutUs;
