import React from "react";
import menu from "../../assets/images/icons/menu.svg";
import casino from "../../assets/images/icons/casino.svg";
import livecasino from "../../assets/images/icons/livecasino.svg";
import chat from "../../assets/images/icons/chat.svg";
import "./style.css";
import { useMyContext } from "../context";
import { Link } from "react-router-dom";

function MenuNav() {
  const { handleMenuClick } = useMyContext();
  return (
    <div className="bottom-nav">
      <ul>
        <li onClick={handleMenuClick}>
          <img src={menu} alt="menu" /> <span text_key="MENU__MENU">ALL</span>
        </li>

        <li>
          <Link to="/all-games">
            <span style={{ display: "none" }}>CASINO</span>
          </Link>{" "}
          <img src={casino} alt="casino" />{" "}
          <span text_key="MENU__CASINO">CASINO</span>
        </li>
        <li className="livecasino">
          <Link to="/all-games">
            <span style={{ display: "none" }}>LIVE CASINO</span>
          </Link>{" "}
          <img src={livecasino} alt="livecasino" />{" "}
          <span text_key="MENU__LIVE_CASINO">LIVE CASINO</span>
        </li>
        <li className="chat">
          <img src={chat} alt="chat" /> <span text_key="MENU__SPORT">CHAT</span>
        </li>
      </ul>
    </div>
  );
}

export default MenuNav;
