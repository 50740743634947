// MyContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { getAllSubCategories } from "../../utility/Apis";
// import { getUserDetail } from "../../utility/Apis";

const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  const initialData = {
    id: "",
    email: "",
    isEmailVerified: "",
    firstName: "",
    role: "",
  };
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [transection, setTransection] = useState("");
  const [activeTab, setActiveTab] = useState("deposit");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menu, setMenu] = useState(false);
  const [isPromo, setIsPromo] = useState(false);
  const [userDetails, setUserDetails] = useState(initialData);

  const initialLoginState = localStorage.getItem("token") || false;
  const [forLogin, setForLogin] = useState(initialLoginState);
  const [loading, setLoading] = useState(false);
  const [gameShow, setGameShow] = useState(false);
  const [isGamePlaying, setIsGamePlaying] = useState(false);
  const [gameDetail, setGameDetail] = useState({});
  const [gameSubCategory, setGameCategories] = useState([]);
  const handleLoginClose = () => {
    setShowLogin(false);
  };
  const handleLoginShow = () => {
    setShowLogin(true);
    setShowSignup(false);
    setGameShow(false);
  };

  const handleSignupClose = () => {
    setShowSignup(false);
  };
  const handleSignupShow = () => {
    setShowSignup(true);
    setShowLogin(false);
    setIsPromo(false);
  };

  const handleForgotClose = () => {
    setShowForgot(false);
    setShowLogin(true);
  };
  const handleForgotShow = () => {
    setShowForgot(true);
    setShowLogin(false);
  };

  const handleResetClose = () => {
    setShowReset(false);
  };
  const handleResetShow = () => {
    setShowReset(true);
  };
  const handleForLogin = () => {
    setForLogin(!forLogin);
    setShowLogin(false);
  };

  const handleShowTransection = (initialTab) => {
    setActiveTab(initialTab);
    setTransection(true);
    setIsPromo(false);
  };
  const handleHideTransection = () => {
    setTransection(false);
  };

  // useEffect(() => {
  //   console.log("activeTab changed:", activeTab);
  // }, [activeTab]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = () => {
    setMenu(!menu);
  };

  const handleMenuClickClose = () => {
    setMenu(false);
  };

  const handlePromoShow = (e) => {
    setIsPromo(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const handlePromoClose = () => {
    setIsPromo(false);
  };
  const getSubCategory = async () => {
    try {
      const res = await getAllSubCategories();
      const {
        data: { categories, successful },
      } = res || {};
      if (successful) {
        setGameCategories(categories);
      }
    } catch (err) {
      console.log("Error in the game subcategories function", err);
    }
  };
  useEffect(() => {
    getSubCategory();
  }, []);

  return (
    <MyContext.Provider
      value={{
        menu,
        isPromo,
        showLogin,
        showSignup,
        showForgot,
        showReset,
        gameShow,
        setGameShow,
        forLogin,
        setForLogin,
        transection,
        activeTab,
        windowWidth,
        handleLoginClose,
        handleLoginShow,
        handleSignupClose,
        handleSignupShow,
        handleForgotClose,
        handleForgotShow,
        handleForLogin,
        handleShowTransection,
        handleHideTransection,
        handleMenuClick,
        handleMenuClickClose,
        handlePromoShow,
        handlePromoClose,
        handleResetShow,
        handleResetClose,
        userDetails,
        setUserDetails,
        loading,
        setLoading,
        isGamePlaying,
        setIsGamePlaying,
        gameDetail,
        setGameDetail,
        gameSubCategory,
        setGameCategories,
        setShowLogin,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export { MyContextProvider, useMyContext };
