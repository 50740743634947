import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AccountLayout from "./index";
import { useMyContext } from "../context";
import back from "../../assets/images/header/icon/back.svg";
import { sendEmailCode, verifyDocument, verifyMail } from "../../utility/Apis";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function Kyc() {
  const { windowWidth, setUserDetails, userDetails } = useMyContext();
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [fileNames, setFileNames] = useState({
    frontside: "No file chosen",
    backside: "No file chosen",
    selfie: "No file chosen",
  });
  const handleGoBack = () => {
    window.history.back();
  };

  // Api call for send Code =================
  const sendCode = async () => {
    try {
      setLoading(true);
      const res = await sendEmailCode();
      if (res.status === 200) {
        setLoading(false);
        toast.success(res?.message, {
          toastId: "send-code",
          autoClose: 2000,
        });
      } else {
        setLoading(false);
        toast.error(res?.message, {
          toastId: "send-code",
          autoClose: 2000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // Api call for email Verification================
  const verifyCode = async () => {
    if (!code) return toast.error("Please fill the code");
    try {
      setVerifyLoading(true);
      const res = await verifyMail({ code: code });
      if (res?.status === 200) {
        toast.success(res?.message, {
          toastId: "verify-code",
          autoClose: 2000,
        });
        setUserDetails({
          ...userDetails,
          isEmailVerified: true,
        });
        setVerifyLoading(false);
      } else {
        setVerifyLoading(false);
        toast.error(res?.message, {
          toastId: "send-code",
          autoClose: 2000,
        });
      }
    } catch (error) {
      setVerifyLoading(false);
      console.log(error);
    }
  };

  // Handle Identity proof Files ===============
  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];

    if (file) {
      setFileNames({
        ...fileNames,
        [fileType]: file.name,
      });
    } else {
      // Reset the file name if no file is chosen
      setFileNames({
        ...fileNames,
        [fileType]: "No file chosen",
      });
    }
  };

  // Api call for Identity verification================
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      fileNames.frontside === "No file chosen" ||
      fileNames.backside === "No file chosen" ||
      fileNames.selfie === "No file chosen"
    )
      return toast.error("Please select all documents", {
        toastId: "file-error",
      });

    const formData = new FormData();
    // Append files to FormData
    formData.append("frontSide", event.target["Document Front Side"].files[0]);
    formData.append("backSide", event.target["Document Back Side"].files[0]);
    formData.append("selfie", event.target["Selfie With Document"].files[0]);

    try {
      // api call for kyc verification
      const res = await verifyDocument(formData);
      if (res?.status === 200) {
        setUserDetails({
          ...userDetails,
          iskycCompleted: true,
        });
        toast.error(res?.message, {
          toastId: "kyc-success",
        });
      } else {
        toast.error(res?.message, {
          toastId: "kyc-error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AccountLayout>
      {windowWidth <= 991 && (
        <div className="page-title">
          <div className="back" onClick={handleGoBack}>
            <img src={back} alt="back" />{" "}
            <span text_key="PAYMENTS__BACK">BACK</span>
          </div>
          <h3 className="page-name" text_key="PROFILE_VERIFY__TITLE">
            TITLE
          </h3>
        </div>
      )}
      <div className="kyc-forms-content">
        <Accordion>
          <div className="kyc-forms">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="kyc-form-collapsable">
                  <div className="form-name">
                    <span className="material-icons">assignment_ind</span>
                    <span>Mail Verification</span>
                  </div>
                  {userDetails?.isEmailVerified === true ? (
                    <div>
                      <span className="material-icons locked">
                        lock_outline
                      </span>
                    </div>
                  ) : (
                    <span className="material-icons icon">
                      keyboard_arrow_right
                    </span>
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="form-content">
                  <div className="form-description">
                    <p>1. Press "Send Code"</p>
                    <p>
                      2. You will receive the verification code to your E-mail
                    </p>
                    <p>3. Enter the verification code</p>
                    <p>4. Press verify</p>
                  </div>
                  <ul className="kyc-questionnaires">
                    <li className="">
                      <div className="kyc-questionnaire-collapsible">
                        <span className="title">Mail</span>
                        <span className="status-message " text_key=""></span>
                      </div>
                      <form className="content" questionnaireid="197">
                        <div className="auto-verify">
                          {userDetails?.isEmailVerified === true ? (
                            <>
                              <div>
                                <h3
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  Verified
                                </h3>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="questionnaire-row">
                                <div
                                  className="btn-grey send-code"
                                  vtype="2"
                                  text_key="KYC__SEND_CODE"
                                  onClick={sendCode}
                                >
                                  {loading ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    "Send code"
                                  )}
                                </div>

                                <input
                                  type="text"
                                  className="kyc-input"
                                  placeholder_key="KYC__ENTER_CODE"
                                  name="code"
                                  placeholder="Enter code"
                                  onChange={(e) => setCode(e.target.value)}
                                />
                                <div
                                  className="btn-save confirm"
                                  text_key="KYC__VERIFY"
                                  onClick={verifyCode}
                                >
                                  {verifyLoading ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    "Verify"
                                  )}
                                </div>
                                {/* <p class="message"></p> */}
                              </div>
                            </>
                          )}
                        </div>
                      </form>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className="kyc-forms">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className="kyc-form-collapsable">
                  <div className="form-name">
                    <span className="material-icons">assignment_ind</span>
                    <span>Proof Of Identity/Selfie</span>
                  </div>

                  {!userDetails?.iskycCompleted ? (
                    <span className="material-icons icon">
                      keyboard_arrow_right
                    </span>
                  ) : (
                    <span className="material-icons locked">lock_outline</span>
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="form-content">
                  <div className="form-description">
                    <p>
                      Please take a selfie with your identity document and paper
                      (please hold the paper and the document together in one
                      hand), on which you’ll write:
                    </p>
                    <p>&nbsp;</p>
                    <p>The current date;</p>
                    <p>Your full name;</p>
                    <p>Username;</p>
                    <p>The name of the site.</p>
                  </div>
                  <ul className="kyc-questionnaires">
                    <li className="">
                      <div className="kyc-questionnaire-collapsible">
                        <span className="title">Proof Of Identity/Selfie</span>
                        <span className="status-message " text_key="">
                          {userDetails?.iskycCompleted ? "Pending" : ""}
                        </span>
                      </div>
                      {!userDetails?.iskycCompleted && (
                        <form
                          className="content"
                          questionnaireid="223"
                          onSubmit={handleSubmit}
                        >
                          <div className="kyc-questionnaire-row">
                            <div className="file-inp-wrapper">
                              <span className="field-name">
                                Document Front Side
                              </span>
                              <label>
                                <input
                                  type="file"
                                  fid="508"
                                  accept="image/png, image/gif, image/jpeg , application/pdf"
                                  name="Document Front Side"
                                  className="required"
                                  onChange={(e) =>
                                    handleFileChange(e, "frontside")
                                  }
                                />
                                <span text_key="KYC__CHOOSE_FILE">
                                  Choose file
                                </span>
                              </label>
                              <div
                                className="max-size"
                                text_key="KYC__MAX_SIZE"
                              >
                                Max size
                              </div>
                              <span
                                className="chosen-file-name"
                                text_key="KYC__NO_FILE_CHOSEN"
                              >
                                {/* No file chosen */}
                                {fileNames?.frontside}
                              </span>
                            </div>

                            <div className="file-inp-wrapper">
                              <span className="field-name">
                                Document Back Side
                              </span>
                              <label>
                                <input
                                  type="file"
                                  fid="509"
                                  accept="image/png, image/gif, image/jpeg , application/pdf"
                                  name="Document Back Side"
                                  className="required"
                                  onChange={(e) =>
                                    handleFileChange(e, "backside")
                                  }
                                />
                                <span text_key="KYC__CHOOSE_FILE">
                                  Choose file
                                </span>
                              </label>
                              <div
                                className="max-size"
                                text_key="KYC__MAX_SIZE"
                              >
                                Max size
                              </div>
                              <span
                                className="chosen-file-name"
                                text_key="KYC__NO_FILE_CHOSEN"
                              >
                                {/* No file chosen */}
                                {fileNames?.backside}
                              </span>
                            </div>

                            <div className="file-inp-wrapper">
                              <span className="field-name">
                                Selfie With Document
                              </span>
                              <label>
                                <input
                                  type="file"
                                  fid="510"
                                  accept="image/png, image/jpeg , application/pdf"
                                  name="Selfie With Document"
                                  className="required"
                                  onChange={(e) =>
                                    handleFileChange(e, "selfie")
                                  }
                                />
                                <span text_key="KYC__CHOOSE_FILE">
                                  Choose file
                                </span>
                              </label>
                              <div
                                className="max-size"
                                text_key="KYC__MAX_SIZE"
                              >
                                Max size
                              </div>
                              <span
                                className="chosen-file-name"
                                text_key="KYC__NO_FILE_CHOSEN"
                              >
                                {/* No file chosen */}
                                {fileNames?.selfie}
                              </span>
                            </div>
                          </div>

                          <div className="kyc-questionnaire-btn">
                            <button className="btn btn-save" type="submit">
                              Save
                            </button>
                          </div>
                        </form>
                      )}
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        </Accordion>
      </div>
    </AccountLayout>
  );
}

export default Kyc;
