import React from "react";
import CategoryLayout from "./index";

function KycPolicy() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title">KYC Policy</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              <strong>AML</strong>
              <strong>
                <span lang="GEO/KAT"> - </span>KYC policy/procedures
              </strong>
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              Standards set out in this AML and KYC policy/procedures documents
              are based on applicable legal and regulatory requirements,
              especially on the EU AML directive (Directive (EU) 2015/849 of the
              European Parliament and of the Council of 20 May 2015 on the
              prevention of the use of the financial system for the purposes of
              money laundering or terrorist financing, amending Regulation (EU)
              No 648/2012 of the European Parliament and of the Council, and
              repealing Directive 2005/60/EC of the European Parliament and of
              the Council and Commission Directive 2006/70/EC (Text with EEA
              relevance) in order to effectively prevent&nbsp;any money
              laundering, terrorist financing or other fraudulent activities.
            </p>
            <p className="MsoNormal">
              The Company uses its best endeavors to adhere to high standards of
              anti-money laundering/counter terrorist financing (AML/CTF)
              regulations and requires management and employees to adhere to
              these standards in order to prevent the use of Company’s products
              and services for money laundering/terrorist financing purposes.
              Implemented AML/CTF compliance program comprise policies and
              procedures including but not limited to:&nbsp;
            </p>
            <ul type="disc">
              <li className="MsoNormal">
                Client identification and verification;
              </li>
              <li className="MsoNormal">
                Ultimate Beneficial Owner identification and verification;
              </li>
              <li className="MsoNormal">Identification of AML/CTF risks;</li>
              <li className="MsoNormal">
                Effective implementation of AML/CTF policies and procedures to
                ensure compliance with AML laws and regulations;
              </li>
              <li className="MsoNormal">
                Enhanced due diligence of high-risk clients;
              </li>
              <li className="MsoNormal">
                Identification of clients, who qualify as Politically Exposed
                Persons (PEPs);
              </li>
              <li className="MsoNormal">
                Internal procedures for monitoring and reporting suspicious
                activities of the client’s;
              </li>
              <li className="MsoNormal">
                Client screening against global list of terrorists and specially
                designated nationalities, relevant financial and other sanctions
                lists;
              </li>
              <li className="MsoNormal">
                Staff training and awareness raising through specialized
                trainings and courses;
              </li>
              <li className="MsoNormal">Maintenance of relevant records;</li>
              <li className="MsoNormal">
                Management of regulatory inquiries and incidents;
              </li>
              <li className="MsoNormal">
                Coordinating day-to-day compliance by responsible compliance
                staff.
              </li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">KYC policy/procedures</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              The Company adheres to high standards of required Know Your Client
              procedures (KYC). These KYC policy/procedures encompass the
              following measures:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">Client’s identification;</li>
              <li className="MsoNormal">
                Client’s acceptance/non-acceptance policy/guidelines (client’s
                under age, clients from restricted/sanctioned territories not
                accepted);
              </li>
              <li className="MsoNormal">Monitoring of high-risk clients;</li>
              <li className="MsoNormal">
                Obtaining of any information, which could be of importance with
                regard to AML risk;
              </li>
              <li className="MsoNormal">
                KYC procedures take place when the respective transaction is to
                be carried out;
              </li>
              <li className="MsoNormal">
                KYC procedures are carried out permanently considering the
                identified risks.&nbsp;
              </li>
            </ul>
            <p className="MsoNormal">
              The Company’s management, employees, agents are trained to deal
              with AML related matters and respond to transactions that raise
              suspicion of money laundering or financing of terrorism.
            </p>
            <p className="MsoNormal">
              Records of all documents obtained for the purpose of
              identification and all transaction data, as well as other
              documents are kept electronically for at least 10 years.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Risk-Based Approach</strong>
            </p>
            <p className="MsoNormal">
              The Company adopts a Risk-Based Approach (RBA) when assessing the
              risks related to AML/CTF. The Company’s guidelines are the
              following:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">
                Prior to conducting any transaction, the Company conducts checks
                to ensure that the identity of the client does not match with
                any person with known criminal background, banned entities, or
                terrorists;
              </li>
              <li className="MsoNormal">
                The relevant data shall be obtained from the client prior to
                conducting the respective transaction;
              </li>
              <li className="MsoNormal">
                The process of risk assessment of the clients may encompass the
                assessment of client’s background, country of origin, source of
                funds and volume of transactions;
              </li>
              <li className="MsoNormal">
                The company may carry out enhanced due diligence with regard to
                client’s, which can be considered as high-risk based on the
                findings of the Company;
              </li>
              <li className="MsoNormal">
                The company may carry out enhanced due diligence with regard to
                funds the origin of which are unclear or for the transactions of
                higher value and frequency.
              </li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">Client Identification</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              The Client Identification Program is to be carried out:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">
                before any financial transaction can be made;&nbsp;
              </li>
              <li className="MsoNormal">
                when there is any doubt about the authenticity, veracity, or
                adequacy of the previously obtained Clients’ identification
                data.
              </li>
              <li className="MsoNormal">
                requires Clients to provide proof of identification and if
                necessary, proof of residence; and
              </li>
              <li className="MsoNormal">
                does not under any circumstances permit any transaction to be
                made with&nbsp;
              </li>
              <li className="MsoNormal">
                incomplete identity and if needed residence verification
                information.
              </li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">Politically Exposed Persons</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              The Company does not transact with individuals who are Politically
              Exposed Persons (PEPs), or their family members. The company uses
              its best endeavor’s to screens all Clients against global PEP
              lists before they are allowed to establish a relationship with the
              Company.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">Required Information</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              Clients go through an automated verification process where they
              submit:
            </p>
            <ul type="disc">
              <li className="MsoNormal">Name;</li>
              <li className="MsoNormal">Email address;</li>
              <li className="MsoNormal">Date of birth;</li>
              <li className="MsoNormal">
                Copy of ID/Passport/Driver’s license;
              </li>
              <li className="MsoNormal">
                Proof of residence (bank statement, Utility bill, similar – not
                older than 6 months).
              </li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">Compliance Officer</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              The Compliance Officer is an employee of the company and is
              responsible for the effective enforcement of the AML/KYC Policy.
              Compliance Officer duties are the following:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">
                Collecting Clients’ identification information;
              </li>
              <li className="MsoNormal">
                Monitoring of the respective transactions;
              </li>
              <li className="MsoNormal">
                Elaborating and establishing internal policies and procedures
                for the completion, review, submission and retention of all
                reports and records as required from competent authorities;
              </li>
              <li className="MsoNormal">Updating risk assessment;</li>
              <li className="MsoNormal">Maintenance of AML records;</li>
              <li className="MsoNormal">
                Providing law enforcement with information as required under the
                applicable laws and regulations;
              </li>
              <li className="MsoNormal">
                The Compliance Officer is responsible and entitled to
                communicate with competent law enforcement authorities, which
                are involved in prevention of money laundering, terrorist
                financing, and other illegal activity;
              </li>
              <li className="MsoNormal">Training of the staff.</li>
            </ul>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default KycPolicy;
