import React, { useState } from "react";
import AccountLayout from "./index";
import { useMyContext } from "../context";
import back from "../../assets/images/header/icon/back.svg";

function Gift() {
  const { windowWidth } = useMyContext();
  const [activeTab, setActiveTab] = useState("freespins");

  const handleTabClick = (page) => {
    setActiveTab(page);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <AccountLayout>
      {windowWidth <= 991 && (
        <div className="page-title">
          <div className="back" onClick={handleGoBack}>
            <img src={back} alt="back" />{" "}
            <span text_key="PAYMENTS__BACK">BACK</span>
          </div>
          <h3 className="page-name" text_key="PROFILE_MENU__MY_GIFTS">
            My Gifts
          </h3>
        </div>
      )}
      <div className="gift-page-wrapper">
        <ul className="tabs">
          <li
            className={activeTab === "freespins" ? "active" : ""}
            onClick={() => handleTabClick("freespins")}
          >
            <span text_key="PROFILE_FREESPINS__FREESPINS">FreeSpins</span>
            <span className="total-count visible disabled" page="freespins">
              0
            </span>
          </li>
          <li
            className={activeTab === "freebets" ? "active" : ""}
            onClick={() => handleTabClick("freebets")}
          >
            <span text_key="PROFILE_FREESPINS__FREEBETS">FreeBets</span>
            <span className="total-count visible disabled" page="freebets">
              0
            </span>
          </li>
          <li
            className={activeTab === "minigames" ? "active" : ""}
            onClick={() => handleTabClick("minigames")}
          >
            <span text_key="PROFILE_FREESPINS__MINIGAMES">MiniGames</span>
            <span className="total-count visible disabled" page="minigames">
              0
            </span>
          </li>
          <li
            className={activeTab === "bonus" ? "active" : ""}
            onClick={() => handleTabClick("bonus")}
          >
            <span text_key="PROFILE_FREESPINS__BONUS">Bonus</span>
            <span className="total-count visible disabled" page="bonus">
              0
            </span>
          </li>
        </ul>
        {/* <!-- <div class='promo-code'>
                    <form>
                        <div class='fields'>
                            <input type='text' name='code' placeholder_key='PROFILE_FREESPINS__PROMO_CODE' required
                                autoComplete='off'>

                            <button class='btn' text_key='PROFILE_FREESPINS__USE'></button>

                            <div class='success-text success'>
                                <i class='material-icons'>done</i>
                                <span text_key='PROFILE_FREESPINS__PROMO_CODE_SUCCESS'></span>
                            </div>

                            <div class='err-text failure'>
                                <i class='material-icons'>close</i>
                                <span></span>
                            </div>

                            <div class='loader-wrapper'>
                                <div class='loader'></div>
                            </div>
                        </div>

                        <div class='txt' text_key='PROFILE_FREESPINS__PROMO_CODE_TEXT'></div>
                    </form>
                </div> --> */}
        <section
          className={`freespins ${activeTab === "freespins" ? "visible" : ""}`}
        >
          <table>
            <thead>
              <tr>
                <th text_key="PROFILE_FREESPINS__CREATE_TIME">Create Time</th>
                <th text_key="PROFILE_FREESPINS__FREESPIN_COUNT">
                  FreeSpin Count
                </th>
                <th text_key="PROFILE_FREESPINS__EXPIRATION_DATE">
                  Expiration Date
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <div className="no-data">
            <div className="material-icons icon">assignment</div>
            <div
              className="text"
              text_key="PROFILE_FREESPINS__NO_FREESPINS_AVAILABLE"
            >
              No FreeSpins Available
            </div>
          </div>
        </section>
        <section
          className={`freebets ${activeTab === "freebets" ? "visible" : ""}`}
        >
          <table>
            <thead>
              <tr>
                <th text_key="PROFILE_FREESPINS__CREATE_TIME">Create Time</th>
                <th text_key="PROFILE_FREESPINS__FREEBET_AMOUNT">
                  {" "}
                  Freebet Amount
                </th>
                <th text_key="PROFILE_FREESPINS__EXPIRATION_DATE">
                  {" "}
                  Expiration Date
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <div className="no-data">
            <div className="material-icons icon">assignment</div>
            <div
              className="text"
              text_key="PROFILE_FREESPINS__NO_FREEBETS_AVAILABLE"
            >
              No Freebets Available
            </div>
          </div>
        </section>
        <section
          className={`minigames ${activeTab === "minigames" ? "visible" : ""}`}
        >
          <table>
            <thead>
              <tr>
                <th text_key="PROFILE_FREESPINS__CREATE_TIME">Create Time</th>
                <th text_key="PROFILE_FREESPINS__TOTAL_AMOUNT">
                  {" "}
                  Total Amount
                </th>
                <th text_key="PROFILE_FREESPINS__SPENT_AMOUNT">
                  {" "}
                  Spent Amount
                </th>
                <th text_key="PROFILE_FREESPINS__EXPIRATION_DATE">
                  {" "}
                  Expiration Date
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <div className="no-data">
            <div className="material-icons icon">assignment</div>
            <div
              className="text"
              text_key="PROFILE_FREESPINS__NO_FREEBETS_AVAILABLE"
            >
              No Freebets Available
            </div>
          </div>
        </section>
        <section className={`bonus ${activeTab === "bonus" ? "visible" : ""}`}>
          <div className="bonus-code">
            <form>
              <div className="fields">
                <input
                  type="text"
                  name="code"
                  placeholder_key="PROFILE_BONUS__BONUS_CODE"
                  required
                  autoComplete="off"
                  placeholder="Bonus Code"
                />
                <button className="btn" text_key="PROFILE_BONUS__USE">
                  Use
                </button>
                <div className="success-text success">
                  <i className="material-icons">done</i>
                  <span text_key="PROFILE_BONUS__BONUS_CODE_SUCCESS"></span>
                </div>
                <div className="err-text failure">
                  <i className="material-icons">close</i> <span></span>
                </div>
                <div className="loader-wrapper">
                  <div className="loader"></div>
                </div>
              </div>
              <div className="txt" text_key="PROFILE_BONUS__BONUS_CODE_TEXT">
                Please Enter Bonus Code
              </div>
            </form>
          </div>
          {/* <div class="unclaimed-bonus-list">
            <h3 text_key="PROFILE_BONUS__CHOOSE_YOUR_BONUS">
              Choose Your Bonus
            </h3>
            <ul>
              <li>
                <h3></h3>
                <h2></h2>
                <div class="desc"></div>
                <div class="actions">
                  <span
                    class="read-more"
                    text_key="PROFILE_BONUS__READ_MORE"
                  ></span>
                  <div class="claim" text_key="PROFILE_BONUS__ACTIVATE"></div>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="bonus-filters">
            <ul className="tabs">
              <li className="active" text_key="PROFILE_BONUS__ALL">
                All
              </li>
              <li status="1" text_key="PROFILE_BONUS__ACTIVE">
                Active
              </li>
              <li status="2" text_key="PROFILE_BONUS__PENDING">
                Pending
              </li>
              <li status="3" text_key="PROFILE_BONUS__COMPLETED">
                Completed
              </li>
              <li status="4" text_key="PROFILE_BONUS__CANCELED">
                Canceled
              </li>
            </ul>
          </div>
          {/* <ul class="bonus-list">
            <li>
              <div class="general-info">
                <div class="name">
                  <h4 text_key="PROFILE_BONUS__BONUS_NAME"></h4>
                  <div class="text"></div>
                </div>
                <div class="bonus-items">
                  <h4 text_key="PROFILE_BONUS__BONUS_ITEMS"></h4>
                  <ul>
                    <li>
                      <img src="../assets/images/bonuses/bonusmoney1b26.png?v2" />
                      <span class="amount"></span> &nbsp;
                      <span text_key="PROFILE_BONUS__BONUS"></span>
                    </li>
                  </ul>
                </div>
                <div class="actions">
                  <span class="status"></span>
                </div>
              </div>
              <div class="detailed-info">
                <ul class="tabs">
                  <li class="active" text_key="PROFILE_BONUS__OVERVIEW"></li>
                </ul>
                <section class="desc">
                  <div class="content-wrapper">
                    <div class="text-content"></div>
                    <div class="progress-bar">
                      <div class="progress-line"></div>
                      <ul class="nums">
                        <li>0</li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li>
                          <span class="material-icons">check_circle</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section></section>
                <section></section>
                <section></section>
              </div>
            </li>
          </ul>
          <div class="overlay unclaimed-bonus-info">
            <div class="popup">
              <h2></h2>
              <span class="material-icons close">close</span>
              <div class="text"></div>
            </div>
          </div>
          <div class="overlay bonusNotAllowed">
            <div class="popup">
              <span class="material-icons close">close</span>
              <p text_key="PAYMENTS__BONUS_NOT_ALLOWED"></p>
            </div>
          </div>
          <div class="overlay status-error">
            <div class="popup">
              <span>
                <span class="material-icons close">close</span>
              </span>
              <div class="content">
                <p class="err-text"></p>
              </div>
            </div>
          </div> */}
        </section>
        {/* <section class="promotions">
          <div class="no-data">
            <div class="material-icons icon">assignment</div>
            <div
              class="text"
              text_key="PROFILE_FREESPINS__NO_FREESPINS_AVAILABLE"
            ></div>
          </div>
        </section> */}
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      </div>
    </AccountLayout>
  );
}

export default Gift;
