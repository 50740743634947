import React from "react";
import AccountLayout from "./index";
import back from "../../assets/images/header/icon/back.svg";
import { useMyContext } from "../context";

function Freespin() {
  const { windowWidth } = useMyContext();
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <AccountLayout>
      {windowWidth <= 991 && (
        <div className="page-title">
          <div className="back" onClick={handleGoBack}>
            <img src={back} alt="back" />{" "}
            <span text_key="PAYMENTS__BACK">BACK</span>
          </div>
          <h3 className="page-name" text_key="PROFILE_BONUS__FREESPINS">
            FREESPINS
          </h3>
        </div>
      )}
      <div className="free-spin-page-wrapper">
        <div className="promo-code">
          <form>
            <div className="fields">
              <input
                type="text"
                name="code"
                placeholder_key="PROFILE_FREESPINS__PROMO_CODE"
                required
                placeholder="Promo Code"
                autoComplete="off"
              />
              <button className="btn" text_key="PROFILE_FREESPINS__USE">
                Use
              </button>
              <div className="success-text success">
                <i className="material-icons">done</i>
                <span text_key="PROFILE_FREESPINS__PROMO_CODE_SUCCESS"></span>
              </div>
              <div className="err-text failure">
                <i className="material-icons">close</i> <span></span>
              </div>
              <div className="loader-wrapper">
                <div className="loader"></div>
              </div>
            </div>
            <div className="txt" text_key="PROFILE_FREESPINS__PROMO_CODE_TEXT">
              Please Enter Promo Code
            </div>
          </form>
        </div>
        <div className="active-freespins">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th text_key="PROFILE_FREESPINS__CREATE_DATE">Create Date</th>
                  <th text_key="PROFILE_FREESPINS__NAME"> Name</th>
                  <th text_key="PROFILE_FREESPINS__AMOUNT">Amount</th>
                  <th text_key="PROFILE_FREESPINS__STATUS"> Status</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div className="no-data">
            <div className="material-icons icon">assignment</div>
            <div
              className="text"
              text_key="PROFILE_FREESPINS__NO_FREESPINS_AVAILABLE"
            >
              No FreeSpins Available
            </div>
          </div>
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
}

export default Freespin;
