import React, { useEffect, useState } from "react";
import {
  matchPath,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import Layout from "../layout/layout";
// import GameSlider from "./gameSlider";
import GameSearch from "./gameSearch";
import "./style.css";
import GameCard from "./gameCard";
//import GamePopup from "./gamePopup";
import { getAllProviders, getAllgamesByCategory } from "../../utility/Apis";
import { useMyContext } from "../context";
import { toast } from "react-toastify";
import titleLogo from "../../assets/images/casinogame/banner/icon.svg";
import arrowdown from "../../assets/images/icons/arrow-down.svg";
import rightArrow from "../../assets/images/casinogame/banner/rightArrow.svg";
import leftArrow from "../../assets/images/casinogame/banner/leftArrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Casino() {
  const {
    loading: loadingShowMore,
    setLoading: setShowMoreLoading,
    userDetails,
    handleLoginShow,
    //setIsGamePlaying,
    windowWidth,
    gameSubCategory,
    //isGamePlaying
  } = useMyContext();
  const navigate = useNavigate();
  const [gameShow, setGameShow] = useState(false);
  const [size, setSize] = useState(null);
  const [allGamesData, setAllGamesData] = useState([]);
  const [page, setPage] = useState(2);
  //const [game, setGame] = useState(null);
  const [shownGames, setShownGames] = useState(1);
  const [providers, setProviders] = useState([]);
  const [providerData, setProviderData] = useState({});
  const [gameLoader, setGameLoader] = useState(false);

  const { pathname } = useLocation();
  const isAllgame = matchPath("/all-games/*", pathname || "dfghjkl");
  const handleGameShow = () => {
    setGameShow(true);
  };
  // const handleGameClose = () => {
  //   setGameShow(false);
  //   setIsGamePlaying(false);
  //   navigate(pathname);
  // };
  const headerGamePopup = gameShow && "gamePopup";

  // Retrieve the value of a specific query parameter
  const { id, name } = useParams() || {};

  const getPageNumber = (pageNum) => {
    if (pageNum > 1) {
      setPage(pageNum);
      getGamesByCatergory();
    }
  };

  const [searchvalue, setSearchValue] = useState("");

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Debounced version of handleSearch
  const debouncedHandleSearch = debounce((value) => {
    setSearchValue(value);
  }, 500);

  const handleSearch = (e) => {
    const { value } = e.target;
    debouncedHandleSearch(value);
  };

  // games list fetch here============
  useEffect(() => {
    window.scrollTo(0, 0);
    const getGames = async () => {
      let payload = {
        limit: 30,
        pageNumber: 1,
        popular: 0,
        isMobile: windowWidth < 576 ? true : false,
      };
      setPage(2);
      if (isAllgame) {
        payload.allGame = true;
        if (providerData?.masterCasinoProviderId) {
          payload.providerId = providerData?.masterCasinoProviderId;
          if (searchvalue) payload.search = searchvalue;
        } else if (searchvalue) {
          payload.search = searchvalue;
        }
      } else if (id) {
        if (providerData?.masterCasinoProviderId) {
          payload.providerId = providerData?.masterCasinoProviderId;
          if (searchvalue) payload.search = searchvalue;
        } else if (searchvalue) {
          payload.search = searchvalue;
        } else {
          payload.subCategoryId = id;
        }
      }
      try {
        setGameLoader(true);
        let res = await getAllgamesByCategory(payload);
        let newGames = res?.data?.games || [];
        if (res?.msg === "success") {
          setSize(res?.data?.queriedCount);
          setShownGames(newGames?.length);
          setAllGamesData(newGames);
          setGameLoader(false);
        } else {
          setGameLoader(false);
        }
      } catch (error) {
        setGameLoader(false);
        console.log(error);
      }
    };
    getGames();
    // eslint-disable-next-line
  }, [providerData?.masterCasinoProviderId, searchvalue, id]);

  const getGamesByCatergory = async () => {
    try {
      let payload = {
        pageNumber: page,
        limit: 45,
        popular: 0,
        isMobile: windowWidth < 576 ? true : false,
      };
      if (isAllgame) {
        payload.allGame = true;
      }
      if (providerData?.masterCasinoProviderId) {
        if (isAllgame) {
          payload.providerId = providerData?.masterCasinoProviderId;
          payload.allGame = true;
        } else if (id) {
          payload.providerId = providerData?.masterCasinoProviderId;
          // payload.subCategoryId = id;
        }
        setPage(page + 1);
      }
      if (id && !providerData?.masterCasinoProviderId) {
        payload.subCategoryId = id;
      }
      setShowMoreLoading(true);
      let res = await getAllgamesByCategory(payload);
      let newGames = res?.data?.games || [];
      // if (!searchvalue) return setAllGamesData(newGames);
      if (res?.msg === "success") {
        setSize(res?.data?.queriedCount);
        setShownGames((prevLength) => prevLength + newGames?.length);
        setAllGamesData((prevData) => [...prevData, ...newGames]);
        setShowMoreLoading(false);
      } else {
        toast.error(res?.msg, {
          toastId: "game-error",
        });
      }
    } catch (error) {
      setShowMoreLoading(false);
      // setGameLoader(false);
      console.log(error);
    }
  };

  const getAllProvidersData = async () => {
    try {
      const res = await getAllProviders();
      if (res?.msg === "success") {
        setProviders(res?.data?.providers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   getGamesByCatergory();
  //   // eslint-disable-next-line
  // }, [page, searchvalue]);

  useEffect(() => {
    getAllProvidersData();
    // eslint-disable-next-line
  }, []);
  // get gameId from onclick===
  const getGameDetails = () => {
    if (!userDetails?.email) {
      handleLoginShow();
    }
  };

  // get proviser details from onclick===
  const getProviderDetails = (provider) => {
    if (provider) setProviderData(provider);
  };

  const settings = {
    speed: 500, // Animation speed in ms
    slidesToShow: 8,
    slidesToScroll: 3,
    infinite: false,
    autoplay: false,
    nextArrow: <img src={rightArrow} alt="right" />,
    prevArrow: <img src={leftArrow} alt="left" />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleFilterGameWithSubCategory = async (categories) => {
    navigate(
      `/view-all/false/${
        categories?.masterGameSubCategoryId
      }/${categories?.name?.replace(/\s+/g, "-")}`
    );
  };
  const handleRedirectOnAllGame = () => {
    navigate("/all-games");
  };
  return (
    <>
      <Layout headerGamePopup={headerGamePopup}>
        <div className="container">
          <main className="casino">
            <div className="all-games-banner">
              <div className="page-title-2">
                <img src={titleLogo} alt="title" />
                <div className="name">
                  <span text_key="CASINO__BRAND">JACKBIT</span>{" "}
                  <span text_key="CASINO__CASINO">CASINO</span>
                </div>
              </div>

              <div className="game-filter-wrapper">
                <GameSearch
                  allGamesData={allGamesData}
                  // onFilteredData={handleFilteredData}
                  page={page}
                  setShowMoreLoading={setShowMoreLoading}
                  allGamesByCategory={getGamesByCatergory}
                  providers={providers}
                  getProviderDetails={getProviderDetails}
                  handlechange={handleSearch}
                />

                <div className="bottom-filter">
                  <Slider {...settings}>
                    <div
                      className="filter-categories"
                      onClick={handleRedirectOnAllGame}
                      role="presentation"
                    >
                      <div className={`category-item ${name ? "" : "active"}`}>
                        Lobby
                      </div>
                    </div>
                    {gameSubCategory &&
                      gameSubCategory?.length > 0 &&
                      gameSubCategory.map((el) => (
                        <div
                          className={`filter-categories`}
                          onClick={() => handleFilterGameWithSubCategory(el)}
                          role="presentation"
                        >
                          <div
                            className={`category-item ${
                              el?.name === name?.replace(/-/g, " ")
                                ? "active"
                                : ""
                            }`}
                          >
                            {el?.name}
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>

                {/* to scroll the categories */}

                <div className="dropdown-cont" tabIndex="0">
                  <div className="custom-select provider-filter" id="">
                    <input
                      type="text"
                      name="categories"
                      placeholder="Categories"
                    />
                    <img
                      className="drop-arrow"
                      src={arrowdown}
                      alt="arrow-down"
                    />
                  </div>
                  <div className="options-wrapper">
                    <ul id="custom-select-opt">
                      <li>
                        Shs
                        <span>35</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="content">
              <GameCard
                handleGameShow={handleGameShow}
                allGamesData={allGamesData}
                loading={loadingShowMore}
                getPageNumber={getPageNumber}
                page={page}
                queriedCount={size}
                getGame={getGameDetails}
                shownGames={shownGames}
                size={size}
                gameLoader={gameLoader}
              />
            </div>
            {/* {userDetails?.email && gameShow && (
              <GamePopup
                handleGameClose={handleGameClose}
                game={game}
                windowWidth={windowWidth}
                isGamePlaying={isGamePlaying}
              />
            )} */}
          </main>
        </div>
      </Layout>
    </>
  );
}

export default Casino;
