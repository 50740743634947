import btc from "../assets/images/bg-icons/2.svg";
import eth from "../assets/images/bg-icons/3.svg";
import ltc from "../assets/images/bg-icons/8.svg";
// import btcc from "../assets/images/bg-icons/1.svg";
// import icon4 from "../assets/images/bg-icons/4.svg";
// import icon5 from "../assets/images/bg-icons/5.svg";
// import icon6 from "../assets/images/bg-icons/6.svg";
// import icon7 from "../assets/images/bg-icons/7.svg";

// import icon9 from "../assets/images/bg-icons/9.svg";
// import icon10 from "../assets/images/bg-icons/10.svg";
// // import icons in serial wise like after 10 it must be 11 and so on.....
// import trxtab from "../assets/images/home/payments/trx.svg";
// import usdctab from "../assets/images/home/payments/usdc.svg";
// import soltab from "../assets/images/home/payments/sol.svg";
// import busdtab from "../assets/images/home/payments/busd.svg";
import usd from "../assets/images/icons/USD.svg";
// import cad from "../assets/images/icons/CAD.svg";
import eur from "../assets/images/icons/EUR.svg";
// import { useMyContext } from "../components/context";
// const { userDetails } = useMyContext();

export const coinData = [
  {
    coin: "bitcoin",
    sym: "BTC",
    rel: "332",
    pci: "97",
    icon: btc,
    alt: "btc",
    // price: userDetails?.cryptoWallet?.eth?.amount,
  },
  {
    coin: "ethereum",
    sym: "ETH",
    rel: "334",
    pci: "509",
    icon: eth,
    alt: "eth",
  },
  {
    coin: "litecoin",
    sym: "LTC",
    rel: "336",
    pci: "514",
    icon: ltc,
    alt: "lte",
  },
  // {
  //   coin: "Bitcoin Cash",
  //   sym: "BCH",
  //   rel: "337",
  //   pci: "508",
  //   icon: btcc,
  // },
  // {
  //   coin: "Ripple",
  //   sym: "XRP",
  //   rel: "335",
  //   pci: "510",
  //   icon: btcc,
  // },
  // {
  //   coin: "Monero",
  //   rel: "339",
  //   pci: "512",
  //   icon: icon6,
  // },
  // {
  //   coin: "Dash",
  //   rel: "340",
  //   pci: "511",
  //   icon: icon7,
  // },
  // {
  //   coin: "Dogecoin",
  //   rel: "341",
  //   pci: "1043",
  //   icon: ltc,
  // },
  // {
  //   coin: "BNB Coin",
  //   rel: "343",
  //   pci: "1041",
  //   icon: icon9,
  // },
  // {
  //   coin: "Tether USD",
  //   rel: "345",
  //   pci: "916",
  //   icon: icon10,
  // },
  // {
  //   coin: "TRON",
  //   rel: "346",
  //   pci: "1035",
  //   icon: trxtab,
  // },
  // {
  //   coin: "USD Coin",
  //   rel: "349",
  //   pci: "1038",
  //   icon: usdctab,
  // },
  // {
  //   coin: "Solana",
  //   rel: "355",
  //   pci: "1047",
  //   icon: soltab,
  // },
  // {
  //   coin: "Binance USD",
  //   rel: "356",
  //   pci: "1048",
  //   icon: busdtab,
  // },
];

export const NetWorkData = [
  {
    coin: "BITCOIN",
    rel: "332",
    pci: "97",
  },
  {
    coin: "Bitcoin/BTCB Token (BC Chain)",
    rel: "353",
    pci: "1045",
  },
  {
    coin: "Bitcoin/BTCB Token (BSC Chain)",
    rel: "354",
    pci: "1046",
  },
];

export const currency = [
  {
    name: "USD",
    rel: "302",
    icon: usd,
  },
  {
    name: "EUR",
    rel: "202",
    icon: eur,
  },
];
