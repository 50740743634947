import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import "./style.css";
import { Link } from "react-router-dom";

function CategoryLayout({ children }) {
  const [activeItem, setActiveItem] = useState(() => {
    // Retrieve the active item from localStorage on component load
    return localStorage.getItem("activeItem") || "aboutus";
  });

  useEffect(() => {
    // Update localStorage with the active item whenever it changes
    localStorage.setItem("activeItem", activeItem);
  }, [activeItem]);

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, []);

  const handleItemClick = (dataKey) => {
    setActiveItem(dataKey);
  };



  return (
    <Layout>
      <div className="container">
        <div className="category-content">
          <CategorySidebar
            activeItem={activeItem}
            handleItemClick={handleItemClick}
          />
          <div className="content-wrapper">{children}</div>
        </div>
      </div>
    </Layout>
  );
}

export default CategoryLayout;

function CategorySidebar({ activeItem, handleItemClick }) {
  return (
    <div className="sidebar-category">
      <div className="navbar">
        <p className="category-title" text_key="FOOTER__INFORMATION">
          Information
        </p>
        <ul>
          <li
            text_key="FOOTER__ABOUT_US"
            data-key="ABOUT_US"
            data-path="aboutus"
          >
            <Link
              to="/static/aboutus"
              className={activeItem === "/static/aboutus" ? "active" : ""}
              onClick={() => handleItemClick("/static/aboutus")}
            >
              {" "}
              About Us
            </Link>
          </li>
          <li
            text_key="FOOTER__GENERAL_TERMS"
            data-key="GENERAL_TERMS"
            data-path="generalterms"
          >
            <Link
              to="/static/generalterms"
              className={activeItem === "/static/generalterms" ? "active" : ""}
              onClick={() => handleItemClick("/static/generalterms")}
            >
              General Terms
            </Link>
          </li>
          <li
            text_key="FOOTER__RESPONSIBLE_GAMING"
            data-key="RESPONSIBLE_GAMING"
            data-path="responsiblegaming"
          >
            <Link
              to="/static/responsiblegaming"
              className={
                activeItem === "/static/responsiblegaming" ? "active" : ""
              }
              onClick={() => handleItemClick("/static/responsiblegaming")}
            >
              Responsible Gaming
            </Link>
          </li>
          <li
            text_key="FOOTER__PRIVACY_POLICY"
            data-key="PRIVACY_POLICY"
            data-path="privacypolicy"
          >
            <Link
              to="/static/privacypolicy"
              className={activeItem === "/static/privacypolicy" ? "active" : ""}
              onClick={() => handleItemClick("/static/privacypolicy")}
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
        <p className="category-title" text_key="FOOTER__HELP">
          Help
        </p>
        <ul>
          <li text_key="FOOTER__FAQ" data-key="FAQ" data-path="faq">
            <Link
              to="/static/faq "
              className={activeItem === "/static/faq" ? "active" : ""}
              onClick={() => handleItemClick("/static/faq")}
            >
              F.A.Q (Frequently Asked Questions)
            </Link>
          </li>
          <li
            text_key="FOOTER__KYC_POLICY"
            data-key="KYC_POLICY"
            data-path="kycpolicy"
          >
            <Link
              to="/static/kycpolicy"
              className={activeItem === "/static/kycpolicy" ? "active" : ""}
              onClick={() => handleItemClick("/static/kycpolicy")}
            >
              KYC Policy
            </Link>
          </li>
          <li
            text_key="FOOTER__REFOUND_POLICY"
            data-key="REFOUND_POLICY"
            data-path="refoundpolicy"
          >
            <Link
              to="/static/refoundpolicy"
              className={activeItem === "/static/refoundpolicy" ? "active" : ""}
              onClick={() => handleItemClick("/static/refoundpolicy")}
            >
              Refund Policy
            </Link>
          </li>
          <li
            text_key="FOOTER__DISPUTE_RESOLUTION"
            data-key="DISPUTE_RESOLUTION"
            data-path="disputeresolution"
          >
            <Link
              to="/static/disputeresolution"
              className={
                activeItem === "/static/disputeresolution" ? "active" : ""
              }
              onClick={() => handleItemClick("/static/disputeresolution")}
            >
              Dispute Resolution
            </Link>
          </li>
          <li
            text_key="FOOTER__FAIRNESS_RNG"
            data-key="FAIRNESS_RNG"
            data-path="fairnessrng"
          >
            <Link
              to="/static/fairnessrng"
              className={activeItem === "/static/fairnessrng" ? "active" : ""}
              onClick={() => handleItemClick("/static/fairnessrng")}
            >
              {" "}
              Fairness & RNG Testing Methods
            </Link>
          </li>
          <li
            text_key="FOOTER__SELF_EXCLUSION"
            data-key="SELF_EXCLUSION"
            data-path="selfexclusion"
          >
            <Link
              to="/static/selfexclusion"
              className={activeItem === "/static/selfexclusion" ? "active" : ""}
              onClick={() => handleItemClick("/static/selfexclusion")}
            >
              {" "}
              Self-Exclusion
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
