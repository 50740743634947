import React from "react";
import CategoryLayout from "./index";

function ResponsibleGame() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title">Responsible Gaming</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              As a responsible iGaming company, we want our users to see
              gambling as a form of fun and entertainment. To avoid problems
              related to gambling, we recommend that you keep the following in
              mind: Gambling is not a source of income, but it is an opportunity
              to have fun when you have extra money and time.
            </p>
            <p className="MsoNormal">
              As a responsible gaming provider, we are committed to creating a
              safe and fun online casino environment for our valued players.
            </p>
            <p className="MsoNormal">
              <strong>&nbsp;</strong>
            </p>
            <p className="MsoNormal">
              <strong>Protecting Underage Users</strong>
            </p>
            <p className="MsoNormal">
              Under a proper regulative bases and internal politics, it is not
              permitted for under 18s to create an account and/or play at our
              site.
            </p>
            <p className="MsoNormal">
              We use our best endeavors to prevent and protect underage users’
              rights. Each user shall take reasonable steps to prevent any
              minors from accessing and/or playing online games:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">
                Do not leave under 18s unattended near your computer or mobile
                when signed in to the casino;
              </li>
              <li className="MsoNormal">
                Do not allow persons under 18 to participate in any gambling
                activity;
              </li>
              <li className="MsoNormal">
                Do not share credit card or bank account details with under 18s;
              </li>
              <li className="MsoNormal">
                Do not use the 'Save Password' option when logging into the
                casino;
              </li>
              <li className="MsoNormal">
                Create separate profiles for everyone who uses your computer;
              </li>
              <li className="MsoNormal">
                Use child-protection software, such as Cyberpatrol, Net Nanny or
                CYBERsitter to block gambling sites from minors;
              </li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              If you have information about someone under the age of 18 who is
              registered with us or accessing the iGaming, please contact us
              immediately at&nbsp;
              <a href="mailto:support@simdibahis.com">
                support@jackbit.com
              </a>{" "}
              &nbsp;
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              Anyone under 18 found playing, or is participated/involved in any
              form will be blocked immediately.&nbsp;
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              As we want to protect underage people from any harms connected
              with us, we do not allow them to register at{" "}
              <a href="http://www.jackbit.com/">www.jackbit.com</a>.&nbsp;
              Somehow, children still engage in iGaming. We, by our side, advise
              parents of underage to use the computer filters that prevent their
              children from gambling websites.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">Here are some of them:</p>
            <p className="MsoNormal">
              <a href="https://mystake.com/en/static/terms/www.netnanny.com">
                www.netnanny.com
              </a>
            </p>
            <p className="MsoNormal">
              <a href="https://mystake.com/en/static/terms/www.cyberpatrol.com">
                www.cyberpatrol.com
              </a>
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Staff Awareness and Qualification</strong>
            </p>
            <p className="MsoNormal">
              It is our responsibility to ensure that all of our staff are
              trained to recognize any signs of problem gambling, and to take
              the necessary steps to protect our members.
            </p>
            <p className="MsoNormal">
              To assist our players in playing responsibly, we provide a
              selection of options:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">Account limits</li>
              <li className="MsoNormal">Time out periods</li>
              <li className="MsoNormal">Self-exclusion periods</li>
              <li className="MsoNormal">24/7 Customer Support</li>
              <li className="MsoNormal">
                Account tools to help you manage your gambling
              </li>
              <li className="MsoNormal">
                Direct links to responsible gambling organizations
              </li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Gambling Management Tools</strong>
            </p>
            <p className="MsoNormal">
              Jackbit offers you the solution in case you need to control your
              behavior connected to our website:
            </p>
            <p className="MsoNormal">
              Gambling Management Tools is the best way to set a limitation by
              yourself. It helps you control your gambling through simple steps.
            </p>
            <p className="MsoNormal">
              In order to set a daily, weekly or monthly deposit limit for your
              playing activity, please contact our Customer Support team who
              will be happy to set your limits based on your request.
            </p>
            <p className="MsoNormal">
              The daily, weekly and monthly limits begin on the date and time
              requested by you, and will be reset once your requested period has
              expired.&nbsp;
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Time Out Period</strong>
            </p>
            <p className="MsoNormal">
              If you need a break from gambling, please contact our Customer
              Support team and ask them to temporarily close your account.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Self-Exclusion</strong>
            </p>
            <p className="MsoNormal">
              You may want to exclude yourself from gambling for an extended
              period of time. Please contact our Customer Support team at any
              time via the official email requesting self-exclusion for the
              period between 6 months to 5 years. The self-Excluded account can
              be reopened prematurely; however, If the situation is more
              serious(Health and/or Mental issues), please inform us, our staff
              will act accordingly.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Do you have problems with gambling?</strong>
            </p>
            <p className="MsoNormal">
              &nbsp;There are few questions that will help you to find out
              whether you have problems with gambling or not:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">
                Do your family members often complain about your engagement with
                gaming?
              </li>
              <li className="MsoNormal">
                Do you find yourself willing to gamble while doing other things?
              </li>
              <li className="MsoNormal">
                Do you think gambling is a way to earn money?
              </li>
              <li className="MsoNormal">
                Are your thoughts and dreams connected with winning the jackpot?
              </li>
              <li className="MsoNormal">
                Is it boring to spend the day without gaming? At least one
                affirmative answer to the above questions is a signal for you to
                start analyzing your attitude towards gambling.&nbsp; In case
                you need help with this issue, here are some people willing to
                avoid your addiction:
              </li>
              <li className="MsoNormal">Gambling Anonymous</li>
              <li className="MsoNormal">GamCare</li>
              <li className="MsoNormal">Gambling Therapy</li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Contact Info</strong>
            </p>
            <p className="MsoNormal">
              If you have any questions or need further information, contact us
              on the following:
            </p>
            <p className="MsoNormal">
              <a href="http://www.jackbit.com/">www.jackbit.com</a>
            </p>
            <p className="MsoNormal">message Live chat</p>
            <p className="MsoNormal">
              We're here to help 24 hours a day, 7 days a week.
            </p>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default ResponsibleGame;
