import React from "react";
import CategoryLayout from "./index";

function DisputeResolution() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title">Dispute Resolution</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              <strong>Dispute Resolution</strong>
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              You are free to contact Our customer service team according to the
              instructions found on the Website to give Us any complaints
              regarding Our Services. 
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              Complaints are handled in the support department and escalated in
              the organization of the Company in the case that support personnel
              did not solve the case immediately. You shall be informed about
              the state of the complaint to a reasonable level.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              If the dispute is not resolved on the casino management level, you
              can contact any independent body, gaming authority or the
              licensing regulator listed on the Website.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              In the event of any dispute, You agree that the server logs and
              records shall act as the final authority in determining the
              outcome of any claim. You agree that in the unlikely event of a
              disagreement between the result that appears on Your screen and
              the game server, the result that was logged on the game server
              will prevail, and You acknowledge and agree that our records will
              be the final authority in determining the terms and circumstances
              of Your participation in the relevant online gaming activity and
              the results of this participation.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              We try and resolve any disagreements quickly and efficiently. If,
              however, player wishes to bring proceedings against us, you must
              do so in competent court in Curacao. These Terms and Conditions
              and the relationship between Player and Jackbit shall be governed
              by, and interpreted in accordance with the laws of Curacao.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">In case of complaints plesae contact:</p>
            <p className="MsoNormal">
              <a href="mailto:support@jackbit.com">support@jackbit.com</a>
            </p>
            <p className="MsoNormal">
              Or use Curacao eGaming license seal set on the website{" "}
              <a href="http://www.jackbit.com">www.jackbit.com</a>
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              When we wish to contact you regarding such a dispute, we will do
              so by using any of the contact details provided in your Player
              Account.
            </p>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default DisputeResolution;
