import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import plus from "../../assets/images/footer/1843a0.svg";
import ssl from "../../assets/images/footer/ssl43a0.svg";
import validate from "../../assets/images/footer/validate.png";
import approve from "../../assets/images/footer/cryptocasinos_approved_full_white.svg";
import bitcoin from "../../assets/images/footer/bitcoin_casino.svg";
import Rev from "../../assets/images/footer/Rev-by-Nettcasino-fav_red.svg";
import BackgroundAnimate from "../backgroundanimate/backgroundAnimate";
// import ResponsiveLanguage from "../language/responsiveLanguage";
// import { useMyContext } from "../context";

function Footer() {
  // const { windowWidth } = useMyContext();
  return (
    <footer className="middle">
      <div className="container">
        <div className="footer-content">
          {/* {windowWidth <= 991 && <ResponsiveLanguage />} */}
          <div className="warning-text">
            <div className="imgs">
              <img src={plus} alt="18plus" /> <img src={ssl} alt="ssl" />
            </div>
            <span className="warning">
              Attention! It is gambling advertising. Gambling is not suitable
              for solving financial problems. Please read terms and conditions
              and play responsibly.
            </span>
          </div>
          <nav>
            <ul>
              <li>
                <p text_key="FOOTER__OUR_PRODUCTS">Our Products</p>
                <ul>
                  <li className="sportsbook">
                    <Link
                      to="sportsbook/prematch.html"
                      text_key="FOOTER__SPORT"
                    >
                      Sports Betting
                    </Link>
                  </li>
                  <li className="sportsbook">
                    <Link to="/" text_key="FOOTER__VIRTUAL_SPORTS">
                      Virtual Sports
                    </Link>
                  </li>
                  <li className="sportsbook">
                    <Link to="/" text_key="FOOTER__ESPORTS">
                      Esports
                    </Link>
                  </li>
                  <li>
                    <Link to="/casino/casino" text_key="FOOTER__CASINO">
                      Casino
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/casino/livecasino"
                      text_key="FOOTER__LIVE_CASINO"
                    >
                      Live Casino
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <p text_key="FOOTER__INFORMATION"> Information</p>
                <ul>
                  <li>
                    <Link
                      to="/static/aboutus"
                      text_key="FOOTER__COMPANY_DETAILS"
                    >
                      Company Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/static/generalterms"
                      text_key="FOOTER__TERMS_CONDITION"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/static/responsiblegaming"
                      text_key="FOOTER__RESPONSIBLE_GAMING"
                    >
                      Responsible Gaming
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/static/privacypolicy"
                      text_key="FOOTER__PRIVACY_POLICY"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="sportsbook">
                    <Link to="/" text_key="FOOTER__SPORTSBOOK_TERMS">
                      Sportsbook Terms
                    </Link>
                  </li>
                  <li>
                    <Link to="/" text_key="FOOTER__PAYOUTS_BONUSES">
                      Payouts Bonuses
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      Jackbit Casino Club
                    </Link>
                  </li>
                  <li>
                    <Link to="/" target="_blank">
                      <span text_key="FOOTER__AFFILIATE">Affiliate </span>
                      <svg
                        width="512"
                        height="512"
                        viewBox="0 0 512 512"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_102_4)">
                          <path
                            d="M469.333 150.399V31.9995C469.333 15.3595 456.555 1.47146 439.979 0.106129C423.445 -1.23787 408.512 10.3248 405.781 26.7515C397.291 77.7381 328.341 85.3541 288 85.3541H117.333C52.6293 85.3328 0 137.962 0 202.666C0 243.178 20.6293 278.954 51.9253 300.031L134.699 486.271C141.653 501.909 157.163 511.999 174.272 511.999C188.053 511.999 201.92 508.031 212.565 499.285C234.069 481.621 240.149 453.29 229.568 429.482L180.907 319.999H287.979C328.32 319.999 397.248 327.615 405.76 378.602C408.341 394.154 421.803 405.333 437.291 405.333C458.645 405.333 469.312 389.973 469.312 373.333V254.933C493.653 249.983 511.979 228.479 511.979 202.666C511.979 176.853 493.675 155.349 469.333 150.399ZM149.333 255.999H117.333C87.936 255.999 64 232.063 64 202.666C64 173.269 87.936 149.333 117.333 149.333H149.333V255.999ZM405.333 281.066C375.232 264.682 335.573 255.999 288 255.999H213.333V149.333H288C335.573 149.333 375.232 140.629 405.333 124.266V281.066Z"
                            fill="#E00D00"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_102_4">
                            <rect width="512" height="512" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <p text_key="FOOTER__HELP">Help</p>
                <ul>
                  <li>
                    <Link to="/static/faq" text_key="FOOTER__FAQ">
                      F.A.Q <span>(Frequently Asked Questions)</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/static/kycpolicy" text_key="FOOTER__KYC_POLICY">
                      KYC Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/static/refoundpolicy"
                      text_key="FOOTER__REFOUND_POLICY"
                    >
                      Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/static/disputeresolution"
                      text_key="FOOTER__DISPUTE_RESOLUTION"
                    >
                      Dispute Resolution
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/static/fairnessrng"
                      text_key="FOOTER__FAIRNESS_RNG"
                    >
                      Fairness & RNG Testing Methods
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/static/selfexclusion"
                      text_key="FOOTER__SELF_EXCLUSION"
                    >
                      Self-Exclusion
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <p text_key="FOOTER__CONTACT_US">Contact Us</p>
                <ul className="social"></ul>
                <Link to="#" text_key="FOOTER__LIVE_CHAT">
                  Live Chat 24/7
                </Link>
                <Link className="link" to="mailto:support@jackbit.com">
                  support@jackbit.com
                </Link>
                <Link className="link" to="mailto:affiliate@jackbit.com">
                  affiliate@jackbit.com
                </Link>
                <Link className="link" to="mailto:vip@jackbit.com">
                  vip@jackbit.com
                </Link>
              </li>
              <li>
                <Link
                  to="https://verification.curacao-egaming.com/validateview.aspx?domain=jackbit.com"
                  target="_blank"
                >
                  <img className="valid-img" src={validate} alt="validate" />
                </Link>
              </li>
            </ul>
          </nav>
          <section className="text">
            <p>
              The website www.jackbit.com is owned and operated by Ryker B.V.
              (registration number: 154186; with registered Address: Abraham
              Mendez Chumaceiro Boulevard 50, Willemstad PO BOX 4750, Curaçao)
              operating through its payment agent RYKER DEVELOPMENT LIMITED
              (registration number: HE412288; registered in Cyprus with
              registered address Griva Digeni 81, MARINOS COURT, 1 &amp; 2th
              floor, 6043 Larnaca, Cyprus). Ryker B.V. is a company registered
              and established under the laws of Curacao and licensed and
              regulated by the Government of Curaçao under the gaming license
              No. 1668/JAZ issued to Curaçao eGaming, authorized and regulated
              by the Government of Curacao with registered address: Pareraweg
              45, Curacao.
            </p>
          </section>
          <div className="bottom-banner">
            <Link to="https://cryptocasinos.com/" target="_blank">
              <img src={approve} alt="" width="145" />{" "}
            </Link>
            <Link to="https://www.nettcasino.com/" target="_blank">
              <img src={Rev} alt="" width="145" />{" "}
            </Link>
            <Link to="https://www.bitcoincasinos.com/" target="_blank">
              <img src={bitcoin} alt="" width="145" />
            </Link>
          </div>

          {/* <div class="loader-wrapper main-loader visible">
          <div class="loader"></div>
        </div> */}
        </div>
      </div>
      <BackgroundAnimate />
    </footer>
  );
}

export default Footer;
