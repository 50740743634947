import React, { useState } from "react";
import play from "../../assets/images/casinogame/gamecard/play.svg";

import { Spinner } from "react-bootstrap";
import { useMyContext } from "../context";
import { useNavigate, useLocation } from "react-router-dom";
// import { height, width } from "@mui/system";

function GameCard({
  handleGameShow,
  allGamesData: gamesData,
  loading,
  getPageNumber,
  page,
  queriedCount,
  getGame,
  shownGames,
  size,
  gameLoader,
}) {
  // const [showFav, setShowFav] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsGamePlaying } = useMyContext();
  const [allGamesShown] = useState(false);

  // console.log(setallGamesShown);
  // const handleChangeFav = () => {
  //   setShowFav(!showFav);
  // };

  const handleChange = (game) => {
    if (game)
      navigate(
        location?.pathname + "?game=" + game?.name?.replace(/\s+/g, "-")
      );
  };

  return (
    <>
      <div className="games-wrapper">
        <ul className={`games`}>
          {gameLoader ? (
            <div
              style={{
                zIndex: 99,
                // textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "20px",
                width: "78vw",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                variant="light"
                style={{ height: "50px", width: "50px" }}
              />
            </div>
          ) : (
            gamesData &&
            gamesData?.map((game) => (
              <li
                key={game.masterCasinoGameId}
                gameid={game?.masterCasinoGameId}
                category={game?.masterGameSubCategoryId}
              >
                <div
                  className="bg"
                  onClick={() => getGame(game)}
                  // style={{ backgroundImage: `url(${game?.thumbnailUrl})` }}
                >
                  <img src={game?.thumbnailUrl} alt="category-games" />

                  <div className="slot-hover">
                    <span onClick={handleGameShow}>
                      <img
                        onClick={() => {
                          handleChange(game);
                          setIsGamePlaying(true);
                        }}
                        className="play"
                        src={play}
                        alt="play icon"
                      />
                    </span>
                    <div className="star-wrapper">
                      <span
                        className="material-icons"
                        // onClick={handleChangeFav}
                      >
                        {true ? "favorite_border" : "favorite"}
                      </span>
                    </div>
                  </div>
                </div>
                <h6 className="pt-2 text-center">
                  {game?.name?.length > 30
                    ? game?.name?.substring(0, 30) + "..."
                    : game?.name}
                </h6>
              </li>
            ))
          )}
        </ul>
        {loading ? (
          <div
            style={{
              zIndex: 99,
              // textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        ) : (
          <>
            <div className="loaded">
              <div className="text-wrapper">
                <span id="rendered-count">{gamesData?.length}</span>{" "}
                <span className="of" text_key="CASINO__OF">
                  OF
                </span>{" "}
                <span id="games-count">{queriedCount}</span>
              </div>
              <div className="progress-bar">
                <div
                  className="line"
                  style={{
                    width: (shownGames / size) * 100 + "%",
                  }}
                ></div>
              </div>
            </div>
            {!allGamesShown &&
              gamesData?.length !== queriedCount &&
              gamesData?.length <= queriedCount && (
                <div
                  className="show-more btn solid"
                  onClick={() => {
                    getPageNumber(page + 1);
                  }}
                  text_key="CASINO__LOAD_MORE"
                >
                  Show more
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
}

export default GameCard;

// function NoData() {
//   <div className="no-data">
//     <div className="material-icons icon">assignment</div>
//     <div className="text" text_key="CASINO__NO_GAMES">
//       No games
//     </div>
//   </div>;
// }
