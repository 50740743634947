/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  // useContext,
} from "react";
import { Button, Spinner } from "react-bootstrap";
import searchIcon from "../../assets/images/header/search.svg";

// import voiceIcon from "../../assets/images/header/voice.svg";
// import en from "../../assets/images/header/flag/en.png";
// import de from "../../assets/images/header/flag/de.png";
// import fr from "../../assets/images/header/flag/fr.png";
// import ru from "../../assets/images/header/flag/ru.png";
// import it from "../../assets/images/header/flag/it.png";
// import es from "../../assets/images/header/flag/es.png";
// import fi from "../../assets/images/header/flag/fi.png";
// import br from "../../assets/images/header/flag/br.png";
// import kr from "../../assets/images/header/flag/ko.png";
// import tr from "../../assets/images/header/flag/tr.png";
// import jp from "../../assets/images/header/flag/ja.png";
// import drop from "../../assets/images/header/arrow_drop.svg";
// import coin from "../../assets/images/header/icon/BTC.svg";
import arrow from "../../assets/images/header/icon/arrow.svg";
// import notification from "../../assets/images/header/icon/notifications.svg";
// import gift from "../../assets/images/header/icon/gift.svg";
import account from "../../assets/images/header/icon/my-account.svg";
import dashboard from "../../assets/images/header/user-profile-icon/dashboard.svg";
//import deposit from "../../assets/images/header/user-profile-icon/depositMoney.svg";
//import withdraw from "../../assets/images/header/user-profile-icon/withdrawMoney.svg";
// import buy from "../../assets/images/header/user-profile-icon/buyCrypto.svg";
//import gifts from "../../assets/images/header/user-profile-icon/gifts.svg";
//import freespins from "../../assets/images/header/user-profile-icon/bonus.svg";
// import notifications from "../../assets/images/header/user-profile-icon/notifications.svg";
import personalInfo from "../../assets/images/header/user-profile-icon/personalInfo.svg";
import transactions from "../../assets/images/header/user-profile-icon/transactions.svg";
import mybets from "../../assets/images/header/user-profile-icon/tik.svg";
// import verification from "../../assets/images/header/user-profile-icon/verification.svg";
// import btc from "../../assets/images/header/balance-icon/BTC.svg";
import gcoin from "../../assets/images/header/new-gold-coin.svg";
import dollarIcon from "../../assets/images/header/dollar-icon.png";
import close from "../../assets/images/icons/close.svg";
import logo from "../../assets/images/header/logo.svg";
import searchTab from "../../assets/images/header/search-tab.svg";
// import tabarrow from "../../assets/images/header/arrow.svg";
import searchtab from "../../assets/images/header/search-tab.svg";
import profileTab from "../../assets/images/header/profile.svg";
import "./style.css";
import { useMyContext } from "../context";
import SearchContent from "./searchContent";
import { Link, useNavigate } from "react-router-dom";
import ResposiveSearch from "./resposiveSearch";
import ResposiveAccount from "./resposiveAccount";
import {
  getAllFilteredGames,
  getUserDetail,
  logoutUser,
} from "../../utility/Apis";
import { toast } from "react-toastify";
import { socket } from "../../socketConnection";
//import GamePopup from "../casino/gamePopup";
import { socoketFun } from "../../App";
import HeaderPopup from "./headerPopup";
import { popupShow } from "../../utility/Apis";
//import IframeGames from "../casino/IframeGame";

// import WalletForm from "./walletForm";

function Header({ headerGamePopup }) {
  const {
    windowWidth,
    forLogin,
    handleLoginShow,
    handleSignupShow,
    //handleShowTransection,
    handleHideTransection,
    userDetails,
    setUserDetails,
    // isGamePlaying,
    setGameDetail,
    setIsGamePlaying,
    gameSubCategory
  } = useMyContext();

  const navigate = useNavigate();
  const inGame = window.location.href.split("/")[3] === "game" ? true : false;
  const [loading, setLoading] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [search, setSearch] = useState(false);
  const [respsearch, setRespsearch] = useState(false);
  const [resaccount, setResAccount] = useState(false);
  // const [refresh, setRefresh] = useState(false);

  const inputRef = useRef(null);
  useEffect(() => {
    if (!inGame && userDetails?.inGame) {
      socket.emit("update-game-status", { inGame, id: userDetails?.id });
      setIsGamePlaying(false);
    }
  }, []);
  const getUserData = async () => {
    try {
      const token = localStorage.getItem("token") || "";
      const res = await getUserDetail();
      if (res.data) {
        setUserDetails(res?.data, { token: token });
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    socket.on("transection_details", (response) => {
      getUserData();
      setUserDetails({
        ...userDetails,
        cryptoWallet: {
          [response?.currency]: response?.updatedUsdAmount,
        },
      });
      // console.log("response=====>>>>>>>", response);
      handleHideTransection();
      toast.success("payment successfull", {
        toastId: "payment",
      });
    });
    socoketFun().on("balance", (response) => {
      setUserDetails({
        ...userDetails,
        cryptoWallet: {
          [response?.currency]: response?.balance,
        },
      });
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("activeAccountMenuLink") || "/account/dashboard"
  );

  useEffect(() => {
    localStorage.setItem("activeAccountMenuLink", activeLink);
  }, [activeLink]);

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    if (search === true) {
      document.body.classList.add("hiddenScoll");
    } else {
      document.body.classList.remove("hiddenScoll");
    }
  }, [search]);

  const handleClickSearch = () => {
    setSearch(!search);
  };

  const handleCloseSearch = () => {
    setSearch(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setAllFilteredGames([]);
  };
  const handleResponsiveShow = () => {
    setRespsearch(true);
    setResAccount(false);
  };

  const handleResponsiveHide = () => {
    setRespsearch(false);
  };

  const handleResAccountShow = () => {
    setResAccount(!resaccount);
  };

  // const handleRefresh = () => {
  //   setRefresh(true);
  //   setTimeout(() => {
  //     setRefresh(false);
  //   }, 1000);
  // };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refreshToken") || "";
    try {
      setLoading(true);
      const res = await logoutUser({
        refreshToken: refreshToken,
      });

      if (res?.status === 200) {
        setLoading(false);
        const items = ["token", "refreshToken"];
        items.forEach((key) => localStorage.removeItem(key));
        toast.success("Logout Successfully");
        window.location.href = "/";
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const useSearchOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearch(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperSearchRef = useRef(null);
  useSearchOutsideAlerter(wrapperSearchRef);

  const handleClickClear = () => {
    // Clear the input field when the "Clear" button is clicked

    // console.log("inputRef.current.value",inputRef.current.value);
    if (inputRef.current) {
      inputRef.current.value = "";
    }

    setAllFilteredGames([]);
  };

  const debounce = (func) => {
    let timer;

    return (...args) => {
      const context = this;
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 600);
    };
  };

  // search functionality =========
  const [allFilteredGames, setAllFilteredGames] = useState([]);
  const searchParams = async (event) => {
    const { value } = event.target;
    if (!value) return setAllFilteredGames([]);
    if (value?.length > 2) {
      try {
        setLoading(true);
        const res = await getAllFilteredGames({
          page: 1,
          limit: 20,
          search: value?.toString() || "",
          isMobile: false,
        });
        if (res?.msg === "success") {
          setLoading(false);
          setAllFilteredGames(res?.data?.games);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  // eslint-disable-next-line
  const optimizedversion = useCallback(debounce(searchParams, []));

  //const [gameShow, setGameShow] = useState(false);
  // const [gameData, setGameData] = useState({});
  const handleGameShow = (game) => {
    // setGameShow(true);
    //setGameData(game);
    setGameDetail(game);
    navigate(`/game/${game?.masterCasinoGameId}`);
  };
  // const handleGameClose = () => {
  //   setGameShow(false);
  //   navigate("/");
  // };

  const [isPopupOpen, setPopupOpen] = useState(true);
  const [imageData, setImageData] = useState(null);

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await popupShow(); // Assuming popupShow is your API function
        setImageData(res?.data?.data); // Set image data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div ref={wrapperSearchRef}>
        <header
          className={`${scrolling ? "scrolled" : ""} ${headerGamePopup} ${
            search ? "search-mode" : ""
          } `}
        >
          {isPopupOpen && imageData && (
            <HeaderPopup onClose={handlePopupClose}>
              {/* {imageData && ( // Check if imageData exists before rendering */}
              <>
                <img src={imageData.image} alt={imageData.detailedText} />
                <p>{imageData.detailedText}</p>
              </>
              {/* )} */}
            </HeaderPopup>
          )}

          <div className="middle">
            <div className="container">
              <div className="header-content">
                <div className="left">
                  {windowWidth <= 991 ? (
                    <div className="tab-logo">
                      <Link to="/" className="logo">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                  ) : (
                    <div className="search-bar" onClick={handleClickSearch}>
                      <div className="wrp">
                        <div className="search-wrapper box">
                          <img src={searchIcon} alt="search" className="icon" />{" "}
                          <input
                            ref={inputRef}
                            placeholder="Main Search"
                            autoComplete="off"
                            type="text"
                            id="search"
                            placeholder_key="SEARCH__SEARCH"
                            onChange={optimizedversion}
                            // onClick={handleClickSearch}
                          />
                          <div className="search-right">
                            <span
                              className="info"
                              text_key="SEARCH__SEARCH_REQUIRES_AT_LEAST_CHARACTERS"
                            >
                              Search requires at least 3 characters
                            </span>{" "}
                            <span
                              className="clear"
                              id="clear-search"
                              text_key="SEARCH__CLEAR"
                              onClick={handleClickClear}
                            >
                              Clear
                            </span>
                            <span
                              className="close"
                              id="close-search"
                              onClick={handleCloseSearch}
                            >
                              <img src={close} alt="clear" />
                            </span>
                          </div>
                        </div>
                        {/* <div className="voice box">
                          <img src={voiceIcon} alt="voice" />
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>

                {!search && (
                  <div className="right">
                    {forLogin ? (
                      <>
                        {windowWidth <= 991 ? (
                          <div id="authorized">
                            <ul>
                              <li className="balance-wrapper">
                                {/* <span
                                  // href="/en/account/deposit"
                                  className="top-up"
                                  onClick={() => {
                                    handleShowTransection("deposit");
                                  }}
                                />{" "} */}
                                <img
                                  className="currency-icon"
                                  src={gcoin}
                                  alt="coin"
                                />{" "}
                                <span id="amount" className="user_amount">
                                  {userDetails?.cryptoWallet?.USD
                                    ? Number(
                                        userDetails?.cryptoWallet?.USD
                                      )?.toFixed(2) || "0.00"
                                    : "0.00"}
                                </span>
                                {/* <svg
                                  // className="refresh-balance"
                                  className={`refresh-balance ${
                                    refresh ? "rotate" : ""
                                  }`}
                                  onClick={handleRefresh}
                                  height="14"
                                  viewBox="0 0 8 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M1.77772 2.67412C2.43552 2.2346 3.20888 2 4 2C4.5083 2 4.86517 2.01717 5.11457 2.04483C5.34513 2.07041 5.53718 2.10932 5.66868 2.18893C5.73956 2.23185 5.85267 2.32346 5.88177 2.486C5.91095 2.64902 5.83522 2.76911 5.79324 2.82139C5.74942 2.87598 5.7008 2.9108 5.67575 2.92746C5.66106 2.93723 5.64801 2.94494 5.63804 2.95053C5.63296 2.95337 5.62845 2.95579 5.62465 2.95778C5.62347 2.9584 5.62234 2.95898 5.62128 2.95953C5.62064 2.95986 5.62002 2.96017 5.61943 2.96047C5.61865 2.96087 5.6179 2.96124 5.6172 2.96159L5.61619 2.9621L5.61571 2.96234C5.61547 2.96245 5.61524 2.96257 5.45455 2.63636L5.61524 2.96257C5.43508 3.05132 5.21709 2.97721 5.12834 2.79706C5.1253 2.79087 5.12244 2.78464 5.11978 2.77838C5.09463 2.77481 5.06627 2.77121 5.03438 2.76767C4.82279 2.7442 4.49579 2.72727 4 2.72727C3.35272 2.72727 2.71997 2.91921 2.18177 3.27883C1.64357 3.63844 1.2241 4.14957 0.976396 4.74758C0.728691 5.34559 0.66388 6.00363 0.790159 6.63848C0.916438 7.27332 1.22813 7.85647 1.68583 8.31417C2.14353 8.77186 2.72668 9.08356 3.36152 9.20984C3.99637 9.33612 4.65441 9.27131 5.25242 9.0236C5.85043 8.7759 6.36156 8.35643 6.72117 7.81823C7.08079 7.28003 7.27273 6.64728 7.27273 6C7.27273 5.79917 7.43553 5.63636 7.63636 5.63636C7.83719 5.63636 8 5.79917 8 6C8 6.79112 7.7654 7.56448 7.32588 8.22228C6.88635 8.88007 6.26164 9.39277 5.53073 9.69552C4.79983 9.99827 3.99556 10.0775 3.21964 9.92314C2.44372 9.7688 1.73098 9.38783 1.17157 8.82843C0.612165 8.26902 0.231202 7.55628 0.0768607 6.78036C-0.0774802 6.00444 0.00173313 5.20017 0.304484 4.46927C0.607234 3.73836 1.11992 3.11365 1.77772 2.67412Z"
                                    fill="#97A1C2"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.10042 0.497655C4.23431 0.367481 4.4514 0.367481 4.58529 0.497655L6.29958 2.16432C6.43347 2.2945 6.43347 2.50555 6.29958 2.63573L4.58529 4.30239C4.4514 4.43257 4.23431 4.43257 4.10042 4.30239C3.96653 4.17222 3.96653 3.96116 4.10042 3.83099L5.57227 2.40002L4.10042 0.96906C3.96653 0.838885 3.96653 0.62783 4.10042 0.497655Z"
                                    fill="#00EC66"
                                  ></path>
                                </svg> */}
                                {/* <span className="currency-name">GC</span>{" "} */}
                                {/* <img
                                  className="arrow"
                                  src={tabarrow}
                                  alt="arrow"
                                /> */}
                                {/* <div className="deposit-alert">
                                <span text_key="MENU__DEPOSIT_NOW_AND_START_PLAYING">
                                  Deposit now and start playing
                                </span>
                                <svg
                                  width="9"
                                  height="9"
                                  viewBox="0 0 9 9"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.56984 4.1669L8.84749 0.889609C9.05095 0.68615 9.05095 0.35628 8.84749 0.152838C8.64403 -0.0506205 8.31416 -0.0506205 8.11072 0.152838L4.83341 3.43047L1.55611 0.152838C1.35265 -0.0506205 1.02278 -0.0506205 0.819342 0.152838C0.6159 0.356297 0.615883 0.686166 0.819342 0.889609L4.09698 4.1669L0.819342 7.44421C0.615883 7.64767 0.615883 7.97754 0.819342 8.18098C1.0228 8.38443 1.35267 8.38444 1.55611 8.18098L4.83341 4.90333L8.1107 8.18098C8.31416 8.38444 8.64403 8.38444 8.84747 8.18098C9.05093 7.97752 9.05093 7.64766 8.84747 7.44421L5.56984 4.1669Z"
                                    fill="#DFDFDF"
                                  ></path>
                                </svg>
                              </div> */}
                              </li>
                              <li
                                className="search-icon box"
                                onClick={handleResponsiveShow}
                              >
                                <img src={searchtab} alt="search" />
                              </li>
                              <li
                                className="account-wrapper user box"
                                onClick={handleResAccountShow}
                              >
                                <img src={profileTab} alt="profile" />
                                <div className="notification-count"></div>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div className="user-info visible">
                            {/* <span
                              className="btn deposit"
                              text_key="MENU__DEPOSIT"
                              onClick={() => {
                                handleShowTransection("deposit");
                              }}
                            > */}
                            {/* GET https://www.coinbase.com/oauth/authorize?response_type=code&client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URL&state=SECURE_RANDOM&scope=wallet:accounts:read */}
                            {/* <Link to="https://www.coinbase.com/oauth/authorize?response_type=code&client_id=c73f4685c27886d91941bd3f3e4522373b900ebcc327233194cc774141057a37&redirect_uri=http://50.16.64.132/&state=SECURE_RANDOM&scope=wallet:accounts:read"> */}
                            {/* Wallet */}
                            {/* </Link> */}
                            {/* </span> */}
                            <div className="wallets-wrapper">
                              <div className="balance">
                                <div className="balance-helper">
                                  {/* <div
                                    className={`refresh-balance ${
                                      refresh ? "rotate" : ""
                                    }`}
                                    onClick={handleRefresh}
                                  >
                                    <svg
                                      width="18"
                                      height="20"
                                      viewBox="0 0 196 234"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M130.5 61.1406C130.5 61.1406 142.87 55.0469 98 55.0469C81.9303 55.0469 66.2214 59.8121 52.86 68.74C39.4985 77.6678 29.0844 90.3573 22.9348 105.204C16.7852 120.05 15.1762 136.387 18.3112 152.148C21.4463 167.909 29.1846 182.386 40.5476 193.749C51.9106 205.112 66.388 212.851 82.149 215.986C97.9099 219.121 114.247 217.512 129.093 211.362C143.94 205.212 156.629 194.798 165.557 181.437C174.485 168.075 179.25 152.367 179.25 136.297"
                                        stroke="white"
                                        strokeWidth="32"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                      />
                                      <path
                                        d="M98 16.4531L138.625 57.0781L98 97.7031"
                                        stroke="white"
                                        strokeWidth="32"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div> */}
                                  <img
                                    className="dollar-coin-icon"
                                    src={dollarIcon}
                                    // here
                                    alt="coin"
                                  />
                                  {/* <span className={`amount`}>
                                    {isGamePlaying
                                      ? "In Game"
                                      : userDetails &&
                                        userDetails?.cryptoWallet?.USD
                                      ? Number(
                                          userDetails?.cryptoWallet?.USD
                                        )?.toFixed(2)
                                      : "0.00"}
                                  </span> */}
                                  {/* To not render in game while user is playing */}
                                  <span>
                                    {userDetails &&
                                    userDetails?.cryptoWallet?.USD
                                      ? Number(
                                          userDetails?.cryptoWallet?.USD
                                        )?.toFixed(2)
                                      : "0.00"}
                                  </span>
                                  <span className="currency-str"></span>{" "}
                                  {/* <img
                                    className="arrow"
                                    src={arrow}
                                    alt="arrow"
                                  /> */}
                                </div>
                                <div className="deposit-alert">
                                  <span text_key="MENU__DEPOSIT_NOW_AND_START_PLAYING">
                                    Deposit now and start playing
                                  </span>
                                  <svg
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.56984 4.1669L8.84749 0.889609C9.05095 0.68615 9.05095 0.35628 8.84749 0.152838C8.64403 -0.0506205 8.31416 -0.0506205 8.11072 0.152838L4.83341 3.43047L1.55611 0.152838C1.35265 -0.0506205 1.02278 -0.0506205 0.819342 0.152838C0.6159 0.356297 0.615883 0.686166 0.819342 0.889609L4.09698 4.1669L0.819342 7.44421C0.615883 7.64767 0.615883 7.97754 0.819342 8.18098C1.0228 8.38443 1.35267 8.38444 1.55611 8.18098L4.83341 4.90333L8.1107 8.18098C8.31416 8.38444 8.64403 8.38444 8.84747 8.18098C9.05093 7.97752 9.05093 7.64766 8.84747 7.44421L5.56984 4.1669Z"
                                      fill="#DFDFDF"
                                    />
                                  </svg>
                                </div>
                                {/* <div className="wallets-list-wrapper">
                                  <div className="options">
                                    <label
                                      className="item"
                                    >
                                      <span text_key="HEADER__DISPLAY_IN_FIAT">
                                        Display In Fiat
                                      </span>{" "}
                                      <input
                                        type="checkbox"
                                        name="fiat"
                                        checked={inFiat}
                                        onChange={handleChangeToggle}
                                      />
                                      <div className="checkmark"></div>
                                    </label>
                                  </div>
                                  <div className="currency-radio-wrapper">
                                    <div class="radio_item">
                                      <label for="180" class="radio-container">
                                        <span>CAD</span>
                                        <input
                                          type="radio"
                                          name="radio"
                                          value="cad"
                                          id="180"
                                          checked={checked.cad}
                                          class="check-curr"
                                          onChange={(e) => {
                                            handleCheck("cad");
                                            handleConvert(e.target.value);
                                          }}
                                        />
                                        <div class="custom-radio"></div>
                                      </label>
                                    </div>
                                    <div class="radio_item">
                                      <label for="202" class="radio-container">
                                        <span>EUR</span>
                                        <input
                                          type="radio"
                                          name="radio"
                                          value="eur"
                                          id="202"
                                          checked={checked.eur}
                                          class="check-curr"
                                          onChange={(e) => {
                                            handleCheck("eur");
                                            handleConvert(e.target.value);
                                          }}
                                        />
                                        <div class="custom-radio"></div>
                                      </label>
                                    </div>
                                    <div class="radio_item">
                                      <label for="302" class="radio-container">
                                        <span>USD</span>
                                        <input
                                          type="radio"
                                          name="radio"
                                          value="usd"
                                          id="302"
                                          checked={checked.usd}
                                          class="check-curr"
                                          onChange={(e) => {
                                            handleCheck("usd");
                                            handleConvert(e.target.value);
                                          }}
                                        />
                                        <div class="custom-radio"></div>
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    className="wallets-helper"
                                    style={{
                                      position: "relative",
                                      overflow: "hidden ",
                                    }}
                                  >
                                    <ul
                                      class="wallets"
                                      style={{
                                        position: "relative",
                                        width: "calc(100% + 17px)",
                                        height: "100%",
                                        overflow: "hidden auto",
                                      }}
                                    >
                                      <WalletForm
                                      amountsData={amountsData}
                                      setCoin={setCoin}
                                      coin={coin}
                                    />
                                    </ul>
                                  </div>
                                </div> */}
                              </div>
                              {/* <div className="circle btn notifications-wrapper">
                                <Link
                                  to="/account/notifications"
                                  className="icon"
                                >
                                  <img src={notification} alt="notifications" />{" "}
                                  <span className="count">5</span>
                                </Link>
                                <div className="notification-data">
                                  <div className="list-wrapper"></div>
                                </div>
                              </div> */}
                              {/* <Link
                                to="/account/gift"
                                className="circle btn gift-wrapper"
                              >
                                <div className="icon">
                                  <img src={gift} alt="gift" />
                                  <span className="count">5</span>
                                </div>
                              </Link> */}
                            </div>
                            <ul>
                              <li>
                                <Link
                                  to="/account/dashboard"
                                  className="btn my-account"
                                >
                                  <span className="username">
                                    {userDetails?.userName
                                      ? userDetails?.userName
                                      : "Loading..."}
                                  </span>
                                  <div className="icon">
                                    <img src={account} alt="my account" />
                                  </div>
                                  <img
                                    className="arrow"
                                    src={arrow}
                                    alt="arrow"
                                  />
                                </Link>
                                <div className="account-menu">
                                  <div className="account-menu-helper box">
                                    <ul>
                                      <li>
                                        <Link
                                          to="/account/dashboard"
                                          className={
                                            activeLink === "/account/dashboard"
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <div className="icon">
                                            <img
                                              src={dashboard}
                                              alt="dashboard"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__DASHBOARD">
                                            Dashboard
                                          </span>
                                        </Link>
                                      </li>
                                      {/* <li>
                                        <span
                                          onClick={() => {
                                            handleShowTransection("deposit");
                                          }}
                                        >
                                          <div className="icon">
                                            <img
                                              src={deposit}
                                              alt="depositMoney"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__DEPOSIT_MONEY">
                                            Deposit Money
                                          </span>
                                        </span>
                                      </li> */}
                                      {/* <li>
                                        <span
                                          onClick={() => {
                                            handleShowTransection("withdraw");
                                          }}
                                        >
                                          <div className="icon">
                                            <img
                                              src={withdraw}
                                              alt="withdrawMoney"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__WITHDRAW_MONEY">
                                            Withdraw Money
                                          </span>
                                        </span>
                                      </li> */}
                                      {/* <li>
                                      <span
                                        onClick={() => {
                                          handleShowTransection("byCrypto");
                                        }}
                                      >
                                        <div className="icon">
                                          <img src={buy} alt="buyCrypto" />
                                        </div>
                                        <span text_key="PROFILE_MENU__BUY_CRYPTO">
                                          Buy Crypto
                                        </span>
                                      </span>
                                    </li> */}
                                      {/* <li>
                                        <Link
                                          to="/account/gift"
                                          className={
                                            activeLink === "/account/gift"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            handleLinkClick("/account/gift");
                                          }}
                                        >
                                          <div className="icon">
                                            <img src={gifts} alt="bonus" />
                                          </div>
                                          <span text_key="PROFILE_MENU__GIFTS">
                                            Gifts
                                          </span>
                                        </Link>
                                      </li> */}
                                      {/* <li>
                                        <Link
                                          to="/account/freespinsbypromo"
                                          className={
                                            activeLink ===
                                            "/account/freespinsbypromo"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            handleLinkClick(
                                              "/account/freespinsbypromo"
                                            );
                                          }}
                                        >
                                          <div className="icon">
                                            <img
                                              src={freespins}
                                              alt="freespinsbypromo"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__FREESPINS_BY_PROMO">
                                            Freespin Promo
                                          </span>
                                        </Link>
                                      </li> */}
                                      {/* <li>
                                        <Link
                                          to="/account/notifications"
                                          className={
                                            activeLink ===
                                            "/account/notifications"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            handleLinkClick(
                                              "/account/notifications"
                                            );
                                          }}
                                        >
                                          <div className="icon">
                                            <img
                                              src={notifications}
                                              alt="notifications"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__NOTIFICATIONS">
                                            Notifications
                                          </span>
                                        </Link>
                                      </li> */}
                                      <li>
                                        <Link
                                          to="/account/personalinfo"
                                          className={
                                            activeLink ===
                                            "/account/personalinfo"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            handleLinkClick(
                                              "/account/personalinfo"
                                            );
                                          }}
                                        >
                                          <div className="icon">
                                            <img
                                              src={personalInfo}
                                              alt="personalInfo"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__PERSONAL_INFORMATION">
                                            Personal Information
                                          </span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/account/transactions"
                                          className={
                                            activeLink ===
                                            "/account/transactions"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            handleLinkClick(
                                              "/account/transactions"
                                            );
                                          }}
                                        >
                                          <div className="icon">
                                            <img
                                              src={transactions}
                                              alt="transactions"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__TRANSACTIONS_HISTORY">
                                            Transactions History
                                          </span>
                                        </Link>
                                      </li>
                                      <li className="sportsbook">
                                        <Link
                                          to="/account/bethistory"
                                          className={
                                            activeLink === "/account/bethistory"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            handleLinkClick(
                                              "/account/bethistory"
                                            );
                                          }}
                                        >
                                          <div className="icon">
                                            <img src={mybets} alt="bets" />
                                          </div>
                                          <span text_key="PROFILE_MENU__BET_HISTORY">
                                            My Bets
                                          </span>
                                        </Link>
                                      </li>
                                      {/* <li>
                                        <Link
                                          to="/account/kyc"
                                          className={
                                            activeLink === "/account/kyc"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() => {
                                            handleLinkClick("/account/kyc");
                                          }}
                                        >
                                          <div className="icon">
                                            <img
                                              src={verification}
                                              alt="verification"
                                            />
                                          </div>
                                          <span text_key="PROFILE_MENU__ACCOUNT_VERIFICATION">
                                            Account Verification
                                          </span>
                                        </Link>
                                      </li> */}
                                    </ul>
                                    <div
                                      className="btn logout"
                                      text_key="PROFILE_MENU__LOG_OUT"
                                      onClick={handleLogout}
                                    >
                                      {loading ? (
                                        <div
                                          style={{
                                            zIndex: 99,
                                            textAlign: "center",
                                          }}
                                        >
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="md"
                                            role="status"
                                            aria-hidden="true"
                                            variant="light"
                                          />
                                        </div>
                                      ) : (
                                        "Log out"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {windowWidth <= 991 ? (
                          <div className="header-tab-btn">
                            <div className="headerButton ">
                              <div
                                className="search-icon box"
                                onClick={handleResponsiveShow}
                              >
                                <img src={searchTab} alt="search" />
                              </div>
                              <Button
                                className="graybtn"
                                onClick={handleLoginShow}
                              >
                                Log In
                              </Button>
                              <Button
                                className="colorbtn"
                                onClick={handleSignupShow}
                              >
                                Sign Up
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="headerButton ">
                            <Button
                              className="graybtn"
                              onClick={handleLoginShow}
                            >
                              Log In
                            </Button>
                            <Button
                              className="colorbtn"
                              onClick={handleSignupShow}
                            >
                              Register
                            </Button>
                          </div>
                        )}
                      </>
                    )}

                    {/* <div className="changeLang">
                      <div className="currentLang">
                        <span className="currentLang">en</span>
                        <img src={en} alt="en" />
                        <span>
                          <img src={drop} alt="drop" />
                        </span>
                        <div className="languageListWrapper">
                          <ul className="box">
                            <li lang="de">
                              <a href="/de">
                                <img src={de} alt="de" />
                                <span text_key="LANGUAGE__DE-DE">
                                  DE - Deutsch
                                </span>
                              </a>
                            </li>
                            <li lang="fr">
                              <a href="/fr">
                                <img src={fr} alt="fr" />
                                <span text_key="LANGUAGE__FR-FR">
                                  FR - Français
                                </span>
                              </a>
                            </li>
                            <li lang="ru">
                              <a href="/ru">
                                <img src={ru} alt="ru" />
                                <span text_key="LANGUAGE__RU-RU">
                                  RU - Русский
                                </span>
                              </a>
                            </li>
                            <li lang="it">
                              <a href="/it">
                                <img src={it} alt="it" />
                                <span text_key="LANGUAGE__IT-IT">IT-IT</span>
                              </a>
                            </li>
                            <li lang="es">
                              <a href="/es">
                                <img src={es} alt="es" />
                                <span text_key="LANGUAGE__ES-ES">
                                  ES - Español
                                </span>
                              </a>
                            </li>
                            <li lang="fi">
                              <a href="/fi">
                                <img src={fi} alt="fi" />
                                <span text_key="LANGUAGE__FI-FI">
                                  FI - Suomalainen
                                </span>
                              </a>
                            </li>
                            <li lang="br">
                              <a href="/br">
                                <img src={br} alt="br" />
                                <span text_key="LANGUAGE__PR-BR">PR-BR</span>
                              </a>
                            </li>
                            <li lang="ko">
                              <a href="/ko">
                                <img src={kr} alt="kr" />
                                <span text_key="LANGUAGE__KO-KR">
                                  KR - 한국어
                                </span>
                              </a>
                            </li>
                            <li lang="tr">
                              <a href="/tr">
                                <img src={tr} alt="tr" />
                                <span text_key="LANGUAGE__TR-TR">
                                  TR - Türkçe
                                </span>
                              </a>
                            </li>
                            <li lang="ja">
                              <a href="/ja">
                                <img src={jp} alt="jp" />
                                <span text_key="LANGUAGE__JA-JP">
                                  JP - 日本語
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
        {search && (
          <SearchContent
            allFilteredGames={allFilteredGames}
            loading={loading}
            // wrapperRef={wrapperSearchRef}
            handleCloseSearch={handleCloseSearch}
            handleGame={handleGameShow}
          />
        )}
      </div>
      {windowWidth <= 991 && (
        <>
          {
            <ResposiveSearch
              handleResponsiveHide={handleResponsiveHide}
              respsearch={respsearch}
              gameSubCategory={gameSubCategory}
            />
          }
          {forLogin && (
            <ResposiveAccount
              resaccount={resaccount}
              handleResAccountShow={handleResAccountShow}
              handleLogout={handleLogout}
            />
          )}
        </>
      )}

      {/* {gameShow && (
        <IframeGames/>
      )} */}
    </>
  );
}

export default Header;
