import React, { useEffect } from "react";
import icon1 from "../../assets/images/bg-icons/1.svg";
import icon2 from "../../assets/images/bg-icons/2.svg";
import icon3 from "../../assets/images/bg-icons/3.svg";
import icon4 from "../../assets/images/bg-icons/4.svg";
import icon5 from "../../assets/images/bg-icons/5.svg";
import icon6 from "../../assets/images/bg-icons/6.svg";
import icon7 from "../../assets/images/bg-icons/7.svg";
import icon8 from "../../assets/images/bg-icons/8.svg";
import icon9 from "../../assets/images/bg-icons/9.svg";
import icon10 from "../../assets/images/bg-icons/10.svg";
import "./styles.css";

function BackgroundAnimate() {
  const iconData = [
    { src: icon1, left: "819px", top: "283px" },
    { src: icon2, left: "252px", top: "278px" },
    { src: icon3, left: "500px", top: "271px" },
    { src: icon4, left: "1331px", top: "217px" },
    { src: icon5, left: "1192px", top: "101px" },
    { src: icon6, left: "114px", top: "71px" },
    { src: icon7, left: "982px", top: "277px" },
    { src: icon8, left: "800px", top: "186px" },
    { src: icon9, left: "216px", top: "157px" },
    { src: icon10, left: "1503px", top: "101px" },
    { src: icon1, left: "482px", top: "3px" },
    { src: icon2, left: "976px", top: "173px" },
    { src: icon3, left: "32px", top: "261px" },
    { src: icon4, left: "873px", top: "129px" },
    { src: icon5, left: "928px", top: "160px" },
    { src: icon6, left: "911px", top: "11px" },
    { src: icon7, left: "550px", top: "82px" },
    { src: icon8, left: "408px", top: "328px" },
    { src: icon9, left: "753px", top: "117px" },
    { src: icon10, left: "640px", top: "188px" },
  ];

  function updateIconPositions() {
    var iconWrappers = document?.querySelectorAll(".icon-wrapper-bg");

    iconWrappers?.forEach(function (iconWrapper) {
      // Generate random top and left values between 10 and 90
      var randomTop = Math.floor(Math.random() * 81) + 10;
      var randomLeft = Math.floor(Math.random() * 81) + 10;

      // Set the style for top and left in percentage
      iconWrapper.style.top = randomTop + "%";
      iconWrapper.style.left = randomLeft + "%";
    });
  }

  // Use the useEffect hook to call updateIconPositions after the initial render
  useEffect(() => {
    updateIconPositions();
  }, []);

  return (
    <div className="bg-component-wrapper">
      <div className="left">
        {iconData.map((icon, index) => (
          <div
            key={index}
            className="icon-wrapper-bg"
            style={{ left: icon.left, top: icon.top }}
          >
            <img
              className="bg-components"
              src={icon.src}
              alt={`payment-icon-${index}`}
            />
          </div>
        ))}
      </div>
      <div className="right">
        {iconData.map((icon, index) => (
          <div
            key={index}
            className="icon-wrapper-bg"
            style={{ left: icon.left, top: icon.top }}
          >
            <img
              className="bg-components"
              src={icon.src}
              alt={`payment-icon-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default BackgroundAnimate;
