import { serverPath } from "./keys.js";
import axios from "axios";

export const authInstance = () =>
  axios.create({
    baseURL: `${serverPath}/v1/auth`,
  });

export const scriptInstance = () =>
  axios.create({
    baseURL: `${serverPath}/v1/script`,
  });

export const userInstance = () =>
  axios.create({
    baseURL: `${serverPath}/v1/users`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  export const userInstanceWithoutToken = () =>
    axios.create({
      baseURL: `${serverPath}/v1/users`,
    });
export const paymentInstance = () =>
  axios.create({
    baseURL: `${serverPath}/v1/payments`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
export const gameInstance = () =>
  axios.create({
    baseURL: `${serverPath}/v1/games`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
