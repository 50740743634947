import React, { useEffect, useState } from "react";
import dashboard from "../../assets/images/header/user-profile-icon/dashboard.svg";
//import deposit from "../../assets/images/header/user-profile-icon/depositMoney.svg";
//import withdraw from "../../assets/images/header/user-profile-icon/withdrawMoney.svg";
// import buy from "../../assets/images/header/user-profile-icon/buyCrypto.svg";
//import gifts from "../../assets/images/header/user-profile-icon/gifts.svg";
//import freespins from "../../assets/images/header/user-profile-icon/bonus.svg";
import notifications from "../../assets/images/header/user-profile-icon/notifications.svg";
import personalInfo from "../../assets/images/header/user-profile-icon/personalInfo.svg";
import transactions from "../../assets/images/header/user-profile-icon/transactions.svg";
import mybets from "../../assets/images/header/user-profile-icon/tik.svg";
import verification from "../../assets/images/header/user-profile-icon/verification.svg";
import { useMyContext } from "../context";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import { logoutUser } from "../../utility/Apis";

function ResposiveAccount({ resaccount, handleResAccountShow, handleLogout }) {
  const { handleForLogin, /* handleShowTransection */ } = useMyContext();

  // const [, setLoading] = useState(false);
  const currentRoute = window.location.pathname;

  const [activeLink, setActiveLink] = useState("");

  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem("activeLink", link);
  };

  useEffect(() => {
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, [activeLink]);

  return (
    <div className={`overlay account-wrapper ${resaccount ? "visible" : ""}`}>
      <div className="user-info">
        <div className="content">
          <ul>
            <li>
              <Link
                to="/account/dashboard"
                className={
                  currentRoute === "/account/dashboard" ? "active" : ""
                }
                onClick={() => handleLinkClick("/account/dashboard")}
              >
                <img src={dashboard} alt="dashboard" />
                <p text_key="PROFILE_MENU__DASHBOARD">Dashboard</p>
              </Link>
            </li>
            {/* <li>
              <span
                onClick={() => {
                  handleShowTransection("deposit");
                }}
              >
                <img src={deposit} alt="depositMoney" />
                <p text_key="PROFILE_MENU__DEPOSIT_MONEY">Deposit Money</p>
              </span>
            </li> */}
            {/* <li>
              <span
                onClick={() => {
                  handleShowTransection("withdraw");
                }}
              >
                <img src={withdraw} alt="withdrawMoney" />
                <p text_key="PROFILE_MENU__WITHDRAW_MONEY">Withdraw Money</p>
              </span>
            </li> */}
            {/* <li>
              <span
                onClick={() => {
                  handleShowTransection("byCrypto");
                }}
              >
                <img src={buy} alt="buyCrypto" />
                <p text_key="PROFILE_MENU__BUY_CRYPTO">Buy Crypto</p>
              </span>
            </li> */}
            {/* <li>
              <Link
                to="/account/gift"
                className={currentRoute === "/account/gift" ? "active" : ""}
                onClick={() => handleLinkClick("/account/gift")}
              >
                <img src={gifts} alt="bonus" />
                <p text_key="PROFILE_MENU__GIFTS">Gifts</p>
              </Link>
            </li> */}
            {/* <li>
              <Link
                to="/account/freespinsbypromo"
                className={
                  currentRoute === "/account/freespinsbypromo" ? "active" : ""
                }
                onClick={() => handleLinkClick("/account/freespinsbypromo")}
              >
                <img src={freespins} alt="freespinsbypromo" />
                <p text_key="PROFILE_MENU__FREESPINS_BY_PROMO">
                  Freespin Promo
                </p>
              </Link>
            </li> */}

            <li className="menu-notification-wrapper">
              <Link
                to="/account/notifications"
                className={
                  currentRoute === "/account/notifications" ? "active" : ""
                }
                onClick={() => handleLinkClick("/account/notifications")}
              >
                <img src={notifications} alt="notification" />
                <p text_key="PROFILE_MENU__NOTIFICATIONS">Notifications</p>
                <div className="count"></div>
              </Link>
            </li>
            <li>
              <Link
                to="/account/personalinfo"
                className={
                  currentRoute === "/account/personalinfo" ? "active" : ""
                }
                onClick={() => handleLinkClick("/account/personalinfo")}
              >
                <img src={personalInfo} alt="personalInfo" />
                <p text_key="PROFILE_MENU__PERSONAL_INFORMATION">
                  Personal Information
                </p>
              </Link>
            </li>
            <li>
              <Link
                to="/account/transactions"
                className={
                  currentRoute === "/account/transactions" ? "active" : ""
                }
                onClick={() => handleLinkClick("/account/transactions")}
              >
                <img src={transactions} alt="transactions" />
                <p text_key="PROFILE_MENU__TRANSACTIONS_HISTORY">
                  Transactions History
                </p>
              </Link>
            </li>
            <li className="sportsbook">
              <Link
                to="/account/bethistory"
                className={
                  currentRoute === "/account/bethistory" ? "active" : ""
                }
                onClick={() => handleLinkClick("/account/bethistory")}
              >
                <img src={mybets} alt="bets" />
                <p text_key="PROFILE_MENU__BET_HISTORY">My Bets</p>
              </Link>
            </li>
            <li>
              <Link
                to="/account/kyc"
                className={currentRoute === "/account/kyc" ? "active" : ""}
                onClick={() => handleLinkClick("/account/kyc")}
              >
                <img src={verification} alt="verification" />
                <p text_key="PROFILE_MENU__ACCOUNT_VERIFICATION">
                  Account Verification
                </p>
              </Link>
            </li>
          </ul>
          <button
            className="btn logout"
            text_key="PROFILE_MENU__LOG_OUT"
            // onClick={handleForLogin}
            onClick={() => {
              handleForLogin();
              handleResAccountShow();
              handleLogout();
            }}
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResposiveAccount;
