import React, { useEffect, useRef, useState } from "react";
import { startselectedGame } from "../../utility/Apis";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../layout/layout";
import { useMyContext } from "../context";

function IframeGames() {
    const {
        windowWidth,
        gameDetail:game
      } = useMyContext();
      const navigate= useNavigate()
  const params = useParams();
  const handleGameClose = () => {
    navigate('/');
  };
  const { gameId } = params;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => {
    const element = document.querySelector(".game-info-wrapper");

    if (!element) return;

    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen(element);
    }
  };

  const enterFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }

    setIsFullscreen(false);
  };

  // const [selectedCurrency, setSelectedCurrency] = useState(null);
  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // const handleGamePay = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };

  // const handleCurrencySelect = (currency) => {
  //   console.log("currency===", currency);
  //   setSelectedCurrency(currency);
  //   setDropdownOpen(false);
  // };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      // setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/casino/casino") {
      setIsActive(true);
    }
  }, []);

  const [gameLink, setGameLink] = useState("");
  const handleStartGame = async () => {
    try {
      const res = await startselectedGame({
        // selectedCurrency: selectedCurrency,
        gameId: gameId,
        isMobile: windowWidth < 500 ? true : false,
      });
      if (res?.success) {
        setGameLink(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (gameId) {
      handleStartGame();
    }
    // eslint-disable-next-line
  }, [gameId]);
  return (
    <Layout>
    <div className={isActive ? "game-popup miniGames" : "game-popup"}>
      <div className="game-info-wrapper">
        <div className="back" onClick={handleGameClose}>
          <BackBtn />
          <span text_key="CASINO__BACK">BACK</span>
        </div>

        <div className="game-wrapper">
          <div className="game">
            <div
              className="iframe-helper"
              // className={isFullscreen ? 'fullscreen-element' : ''}
            >
              {/* {gameLink ? ( */}
              <iframe src={gameLink} frameborder="0" title="Game-play"></iframe>
              {/* ) : (
                <div className="choose-currency"> */}
              {/* <div
                    className="select"
                    name="userCurrencySelect"
                    onClick={handleGamePay}
                  >
                    <div className="select-styled active">
                      {selectedCurrency
                        ? selectedCurrency
                        : "Select Game Currency"}
                    </div>
                    {isDropdownOpen && (
                      <ul className="select-options" ref={dropdownRef}>
                        <li
                          onClick={() => handleCurrencySelect("BTC")}
                          rel="302"
                        >
                          <div className="icon-wrapper">
                            <img src={btc} alt="btc" />
                          </div>
                          BTC
                        </li>
                        <li
                          onClick={() => handleCurrencySelect("ETH")}
                          rel="180"
                        >
                          <div className="icon-wrapper">
                            <img src={eth} alt="eth" />
                          </div>
                          ETH
                        </li>
                        <li
                          onClick={() => handleCurrencySelect("LTC")}
                          rel="202"
                        >
                          <div className="icon-wrapper">
                            <img src={ltc} alt="ltc" />
                          </div>
                          LTC
                        </li>
                      </ul>
                    )}
                  </div> */}
              {/* <div
                    className="btn play-game"
                    text_key="CASINO__PLAY_GAME"
                    onClick={() => handleStartGame()}
                  >
                    Play Game
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className="game-review">
            <div className="game-review-container">
              <div className="game-info">
                <div className="title">{game?.name}</div>
                <p>{(game && game["MasterCasinoProvider.name"]) || ""}</p>
              </div>
              <div className="fav-container"></div>
            </div>
            <div className="action-buttons">
              <div className="full-screen" onClick={toggleFullscreen}>
                <FullScreen />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default IframeGames;

function BackBtn() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 9.16666H7.5L10.2417 6.42499C10.3198 6.34752 10.3818 6.25535 10.4241 6.1538C10.4664 6.05225 10.4882 5.94333 10.4882 5.83332C10.4882 5.72331 10.4664 5.61439 10.4241 5.51284C10.3818 5.41129 10.3198 5.31912 10.2417 5.24166C10.0855 5.08645 9.87432 4.99933 9.65417 4.99933C9.43401 4.99933 9.2228 5.08645 9.06667 5.24166L5.49167 8.82499C5.17868 9.13611 5.00186 9.55868 5 9.99999C5.00406 10.4384 5.1807 10.8576 5.49167 11.1667L9.06667 14.75C9.14437 14.8271 9.2365 14.8882 9.33781 14.9298C9.43912 14.9713 9.54762 14.9925 9.65711 14.9921C9.76661 14.9917 9.87496 14.9698 9.97597 14.9275C10.077 14.8853 10.1687 14.8235 10.2458 14.7458C10.323 14.6681 10.3841 14.576 10.4256 14.4747C10.4672 14.3734 10.4884 14.2649 10.488 14.1554C10.4876 14.0459 10.4656 13.9375 10.4234 13.8365C10.3811 13.7355 10.3194 13.6438 10.2417 13.5667L7.5 10.8333H15.8333C16.0543 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99999C16.6667 9.77898 16.5789 9.56701 16.4226 9.41073C16.2663 9.25445 16.0543 9.16666 15.8333 9.16666Z"
        fill="#CCD6F6"
        fill-opacity="0.72"
      />
    </svg>
  );
}

function FullScreen() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_21)">
        <path
          d="M23 16C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1054 16.4804 22 16.7348 22 17V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22H17C16.7348 22 16.4804 22.1054 16.2929 22.2929C16.1054 22.4804 16 22.7348 16 23C16 23.2652 16.1054 23.5196 16.2929 23.7071C16.4804 23.8946 16.7348 24 17 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V17C24 16.7348 23.8946 16.4804 23.7071 16.2929C23.5196 16.1054 23.2652 16 23 16Z"
          fill="#CCD6F6"
          fill-opacity="0.72"
        />
        <path
          d="M7 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8C1.26522 8 1.51957 7.89464 1.70711 7.70711C1.89464 7.51957 2 7.26522 2 7V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H7C7.26522 2 7.51957 1.89464 7.70711 1.70711C7.89464 1.51957 8 1.26522 8 1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0Z"
          fill="#CCD6F6"
          fill-opacity="0.72"
        />
        <path
          d="M7 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957 16.1054 1.26522 16 1 16C0.734784 16 0.48043 16.1054 0.292893 16.2929C0.105357 16.4804 0 16.7348 0 17L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H7C7.26522 24 7.51957 23.8946 7.70711 23.7071C7.89464 23.5196 8 23.2652 8 23C8 22.7348 7.89464 22.4804 7.70711 22.2929C7.51957 22.1054 7.26522 22 7 22Z"
          fill="#CCD6F6"
          fill-opacity="0.72"
        />
        <path
          d="M19 0H17C16.7348 0 16.4804 0.105357 16.2929 0.292893C16.1054 0.48043 16 0.734784 16 1C16 1.26522 16.1054 1.51957 16.2929 1.70711C16.4804 1.89464 16.7348 2 17 2H19C19.7956 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V7C22 7.26522 22.1054 7.51957 22.2929 7.70711C22.4804 7.89464 22.7348 8 23 8C23.2652 8 23.5196 7.89464 23.7071 7.70711C23.8946 7.51957 24 7.26522 24 7V5C23.9984 3.67441 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0Z"
          fill="#CCD6F6"
          fill-opacity="0.72"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_21">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
