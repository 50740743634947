import React, { useState } from "react";
// import back from "../../assets/images/header/auth/icon/back.svg";
import email from "../../assets/images/header/auth/icon/email43a0.svg";
import { forgotPassword } from "../../utility/Apis";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function ForgotPopup({ handleForgotClose }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formSubmit = async (data, e) => {
    e.preventDefault();
    // ===============call forgot password api ===============
    try {
      setLoading(true);
      const res = await forgotPassword(data);
      const { status } = res || {};
      if (status === 200) {
        handleForgotClose();
        setLoading(false);
        //You can add Toast here
        toast.success(res?.message, {
          autoClose: 2000,
        });
      } else {
        setLoading(false);
        setError(res?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="overlay forgetpassword-popup check-email">
      <div className="popup box">
        <span onClick={handleForgotClose} className="LeftArrowBtn">
          <LeftArrow />
          {/* <img className="back" src={back} alt="back" /> */}
        </span>
        <div className="content">
          <form className="reset-password" onSubmit={handleSubmit(formSubmit)}>
            <ul className="fields">
              <li>
                <h2 text_key="RESET_PASSWORD__RESET_PASSWORD">
                  Reset Password
                </h2>
              </li>
              <li>
                <p text_key="RESET_PASSWORD__ENTER_EMAIL" className="Enter-text">Enter Email</p>
              </li>
              <li>
                <input
                  type="text"
                  name="Email"
                  placeholder="Email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.com)$/,
                      message: "Email must be in a valid format",
                    },
                  })}
                />

                <img src={email} alt="email" />
              </li>
              {errors?.email && errors?.email.message && (
                <li className="err-text" style={{ textAlign: "left" }}>
                  {errors?.email.message}
                </li>
              )}

              {/* <li className="err-text" field="Email" style="display: none"></li> */}
              <li>
                <button
                  className="btn"
                  text_key="RESET_PASSWORD__SEND_INSTRUCTIONS"
                  type="submit"
                >
                  {loading ? <Spinner /> : "Send Instructions"}
                </button>
                {
                  <p
                    style={{
                      color: "red",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {error}
                  </p>
                }
                {/* <div className="loader-wrapper">
                  <div className="loader"></div>
                </div> */}
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPopup;

export const LeftArrow = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 0.6 0.6"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.425 0.3H0.2"
        stroke="#fff"
        stroke-width="0.05"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.275 0.2 0.175 0.3l0.1 0.1"
        stroke="#fff"
        stroke-width="0.05"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
